&--search {
  .page__title {
    color: $color-text;

    strong {
      color: $color-primary;
      font: {
        weight: 600;
      }
    }
  }

  .filters__item--active {
    @include media('<=medium') {
      display: none;
    }
  }

  .products {
    position: relative;

    &::before { //graphical grey border
      @include media('>=xlarge') {
        content: '';
        position: absolute;
        top: 200px;
        right: -100vw;
        left: 83%;
        height: 600px;
        border: {
          bottom: 10px solid map-get($gray, xlight);
          left: 10px solid map-get($gray, xlight);
        }
      }
    }

    &.no-border {
      &::before {
        display: none;
      }
    }
  }

  .contents--grid {
    &::before { //graphical grey border
      content: '';
      position: absolute;
      top: 120px;
      right: -100vw;
      left: 83%;
      height: 0;
      padding-top: 100%;
      border: {
        bottom: 10px solid map-get($gray, xlight);
        left: 10px solid map-get($gray, xlight);
      }

      @include media('>=large') {
        padding-top: 25%;
      }
    }
  }
}
