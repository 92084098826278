&--homepage-services {
  position: relative;

  &::before { //graphical grey border
    position: absolute;
    top: -70px;
    right: 15%;
    bottom: 30%;
    left: -100vw;
    border: {
      top: 0;
      right: 10px solid map-get($gray, xlight);
      bottom: 10px solid map-get($gray, xlight);
      left: 0;
    }

    @include media('>=xlarge') {
      content: '';
      right: 20%;
      bottom: 41%;
    }
  }

  .section__header {
    margin: {
      top: 60px;
      bottom: 35px;
    }

    @include media('>=medium') {
      margin: {
        top: 100px;
        bottom: 45px;
      }
    }
  }

  .section__text,
  .section__title {
    @include media('>large') {
      width: 75%;
    }
  }

  .contents--image {
    .item {
      &__picture {
        position: relative;
        height: 0;
        overflow: hidden;
        padding-top: 70%;
        background-color: map-get($gray, xlight);
        transition: transform $scale-duration $ease-out;

        .image {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: auto;
          z-index: 1;
        }
      }
    }

    &.contents--3-images {
      .item__picture {
        @include media('>large') {
          padding-top: 119%;
        }
      }
    }
  }
}
