&--projects-single {
  overflow: visible;

  @include media('>=large') {
    margin-bottom: 180px;
  }

  > .wrapper {
    @include media('>=large') {
      display: flex;
      align-items: center;

      &::before {
        bottom: 0;
      }
    }
  }

  .section__header {
    @include media('>=large') {
      width: 50%;
    }

    .section__title,
    .section__text {
      @include media('>=large') {
        width: calc(100% - 80px);
      }
    }
  }

  .contents--inner {
    @include media('>=large') {
      position: relative;
      width: 50%;
      margin: {
        right: 0;
        bottom: -80px;
        left: 0;
      }
    }
  }
}
