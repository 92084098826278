.contact {
  padding-bottom: $padding-large;

  @include media('>=large') {
    display: flex;
  }

  .form__wrapper:first-child {
    @include media('>=large') {
      padding-top: 0;
    }
  }

  &__form {
    @include media('>=large') {
      width: calc(100% - 430px);
      flex: 1 1  auto;
      padding: {
        right: 40px;
      }
    }

    @include media('>=full') {
      width: calc(100% - 500px);
      padding: {
        right: 160px;
      }
    }

    &--success {
      #contact_submit {
        border-color: map-get($color-utils, 'success');
        font-size: 0;
        pointer-events: none;

        &::before {
          background-color: map-get($color-utils, 'success');
        }

        &::after {
          content: url('./../../images/checkmark.svg');
          width: 20px;
          height: 20px;
        }
      }
    }
  }

  &__aside {
    @include media('>=large') {
      width: 430px;
      flex: 1 1 auto;
      padding-top: 45px;
    }

    @include media('>=full') {
      width: 500px;
    }
  }

  .form__info,
  .form__label {
    a {
      color: $color-primary;
      font: {
        weight: 600;
      }
    }
  }

  .form__wrapper {
    .button--regular {
      width: 100%;
    }
  }

  .contact-social__image {
    image {
      max-width: 100%;
    }
  }
}

.contact-social {

  &__image {
    display: none;
    position: relative;
    width: 100%;
    height: 0;
    overflow: hidden;
    margin-bottom: $padding-small;
    padding-top: 136%;
    background-color: map-get($gray, xlight);

    .image {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: auto;
    }


    @include media('>=large') {
      display: block;
    }
  }

  &__title {
    color: $color-primary;
    font-size: rem(18px);
  }

  &__list {
    display: inline-flex;
    margin: 0 (- $padding-small);
  }

  &__link {
    display: block;
    position: relative;
    padding: $padding-small;

    .svg-icon {
      width: 25px;
      height: 25px;
      fill: $color-primary;
    }

    &:hover {
      &::before {
        opacity: 1;
      }
    }

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      background-color: map-get($gray, 'xlight');
      opacity: 0;
      z-index: -1;
      transform: translate(-50%, -50%);
      transition: opacity $duration-fast $ease;
    }
  }
}
