//Open Sans
@font-face {
  font-family: 'open-sans';
  src: url('../../fonts/opensans/opensans-regular.eot');
  src: url('../../fonts/opensans/opensans-regular.eot?#iefix') format('embedded-opentype'),
  url('../../fonts/opensans/opensans-regular.woff2') format('woff2'),
  url('../../fonts/opensans/opensans-regular.woff') format('woff'),
  url('../../fonts/opensans/opensans-regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'open-sans';
  src: url('../../fonts/opensans/opensans-semibold.eot');
  src: url('../../fonts/opensans/opensans-semibold.eot?#iefix') format('embedded-opentype'),
  url('../../fonts/opensans/opensans-semibold.woff2') format('woff2'),
  url('../../fonts/opensans/opensans-semibold.woff') format('woff'),
  url('../../fonts/opensans/opensans-semibold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'open-sans';
  src: url('../../fonts/opensans/opensans-bold.eot');
  src: url('../../fonts/opensans/opensans-bold.eot?#iefix') format('embedded-opentype'),
  url('../../fonts/opensans/opensans-bold.woff2') format('woff2'),
  url('../../fonts/opensans/opensans-bold.woff') format('woff'),
  url('../../fonts/opensans/opensans-bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'open-sans';
  src: url('../../fonts/opensans/opensans-extrabold.eot');
  src: url('../../fonts/opensans/opensans-extrabold.eot?#iefix') format('embedded-opentype'),
  url('../../fonts/opensans/opensans-extrabold.woff2') format('woff2'),
  url('../../fonts/opensans/opensans-extrabold.woff') format('woff'),
  url('../../fonts/opensans/opensans-extrabold.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}
// Raleway
@font-face {
  font-family: 'raleway';
  src: url('../../fonts/raleway/raleway-regular.eot');
  src: url('../../fonts/raleway/raleway-regular.eot?#iefix') format('embedded-opentype'),
  url('../../fonts/raleway/raleway-regular.woff2') format('woff2'),
  url('../../fonts/raleway/raleway-regular.woff') format('woff'),
  url('../../fonts/raleway/raleway-regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'raleway';
  src: url('../../fonts/raleway/raleway-semibold.eot');
  src: url('../../fonts/raleway/raleway-semibold.eot?#iefix') format('embedded-opentype'),
  url('../../fonts/raleway/raleway-semibold.woff2') format('woff2'),
  url('../../fonts/raleway/raleway-semibold.woff') format('woff'),
  url('../../fonts/raleway/raleway-semibold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'raleway';
  src: url('../../fonts/raleway/raleway-bold.eot');
  src: url('../../fonts/raleway/raleway-bold.eot?#iefix') format('embedded-opentype'),
  url('../../fonts/raleway/raleway-bold.woff2') format('woff2'),
  url('../../fonts/raleway/raleway-bold.woff') format('woff'),
  url('../../fonts/raleway/raleway-bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'raleway';
  src: url('../../fonts/raleway/raleway-black.eot');
  src: url('../../fonts/raleway/raleway-black.eot?#iefix') format('embedded-opentype'),
  url('../../fonts/raleway/raleway-black.woff2') format('woff2'),
  url('../../fonts/raleway/raleway-black.woff') format('woff'),
  url('../../fonts/raleway/raleway-black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}
