.store,
.product {
  margin-bottom: -60px;

  @include media('>=large') {
    margin-bottom: -140px;
  }

  @include media('>=full') {
    margin-bottom: -160px;
  }

  &__wrapper {

    @include media('>=large') {
      display: flex;
      min-height: 350px;
    }

    @include media('>=full') {
      min-height: 400px;
    }
  }

  &__content {
    position: relative;
    margin: 0 (-$padding-small);
    padding: $padding-small;
    background-color: map-get($gray, 'xlight');

    @include media('>=medium') {
      margin: 0 (-$padding-medium);
      padding: $padding-medium / 2 $padding-medium;
    }

    @include media('>=large') {
      max-width: 70%;
      flex-basis: 70%;
      margin: 0;
      padding: $padding-large 20% $padding-large 0;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        right: 100%;
        width: 100vw;
        height: 100%;
        background-color: map-get($gray, 'xlight');
      }
    }

    a:not(.tabs-nav__link) {
      display: inline-flex;
      align-items: center;
      color: $color-primary;
      font-weight: 600;

      .svg-icon {
        margin-right: 5px;
        fill: $color-primary;
      }
    }
  }

  &__description {
    margin: 30px 0;

    h1 {
      margin: $padding-medium 0;
      font: {
        family: $font-secondary;
        size: rem(18px);
        weight: 600;
      }
    }

    ul {
      li {
        position: relative;
        padding: 10px 0 10px 25px;

        &::before {
          content: '';
          position: absolute;
          top: 18px;
          left: 0;
          width: 10px;
          height: 1px;
          background-color: map-get($gray, medium);
        }
      }
    }
  }

  &__list {
    margin: 20px (- $padding-small);

    @include media('>=medium') {
      display: flex;
      flex-wrap: wrap;
      margin: 25px (- $padding-small);
    }
  }

  &__item {
    padding: 20px;

    @include media('>=medium') {
      max-width: 50%;
      flex-basis: 50%;
      padding: 25px $padding-small;
    }

    p {
      line-height: rem(30px);
    }
  }

  &__title {
    font-weight: 600;
  }

  &__share {
    margin: 0 (-$padding-small);
    padding: 0 $padding-small 45px;
    background-color: map-get($gray, 'xlight');

    @include media('>=medium') {
      margin: 0 (-$padding-medium);
      padding: 0 $padding-medium 45px;
    }

    @include media('>=large') {
      display: flex;
      max-width: 30%;
      flex-basis: 30%;
      align-items: flex-start;
      justify-content: flex-end;
      margin: 0;
      padding: $padding-small;
      background-color: transparent;
    }
  }

  &__suggest {
    margin: 0 (-$padding-small);

    @include media('>=medium') {
      margin: 0;
    }

    .item__picture,
    .page__picture {
      position: relative;
      height: 0;
      overflow: hidden;
      padding-top: 100%;
      background-color: map-get($gray, xlight);

      &:hover {
        .image {
          transform: scale($image-scale-hover);
        }
      }

      .image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: auto;
        margin: 0;
        transition: transform $scale-duration $ease-out;
      }
    }
  }

  &__modals {
    display: none;
  }

  &-section {
    margin: 60px 0;

    @include media('>=large') {
      margin: 140px 0;
    }

    @include media('>=full') {
      margin: 160px 0;
    }

    &__title {
      letter-spacing: 1px;
      color: $color-primary;
      font: {
        family: $font-secondary;
        size: rem(24px);
        weight: 600;
      }

      &--large {
        font-size: rem(18px);

        @include media('>=medium') {
          font-size: rem(36px);
        }
      }
    }
  }

  &-map,
  &-streetview {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    .gm-style .gm-style-iw-c {
      border-radius: 0;

      button {
        top: 6px !important;
        right: 6px !important;
      }
    }

    .item {
      max-width: 325px;
      padding: $padding-medium;
      text-align: center;
      font: {
        family: $font-primary;
        size: rem(14px);
      }

      strong {
        font-weight: 600;
      }

      a {
        display: inline-block;
        padding-top: $padding-small;
        color: $color-primary;
        font-weight: 600;
      }
    }

    iframe {
      width: 100%;
      height: 100%;
    }
  }
}
