.products {
  display: flex;
  flex-wrap: wrap;
  margin: 0 (- $gap-xsmall);
  padding: 0 0 calc(100px + #{$padding-medium});

  @include media('>=large') {
    margin: 0 (- $gap-medium);
  }
}

.products,
.products-modal {
  .item {
    position: relative;
    max-width: 50%;
    flex-basis: 50%;
    padding: $gap-xsmall;

    @include media('>=medium', '<large') {
      padding: 10px;

      &.item--right,
      &:nth-child(2n) {

        &.item--extra {
          &::before {
            right: auto;
          }
        }

        &.item--extra .item__wrapper {
          padding: $padding-large 0 $padding-large $padding-large;
        }
      }
    }

    @include media('>=large') {
      max-width: calc(100% / 3);
      flex-basis: calc(100% / 3);
      padding: $gap-medium;

      &:nth-child(3n - 2) {
        &.item--extra {
          &::before {
            right: $gap-medium;
            left: auto;
          }
        }
      }

      &:nth-child(3n - 1) {
        margin-top: 100px;
        margin-bottom: -100px;

        &.item--extra .item__wrapper {
          padding: $padding-small;
        }
      }

      &.item--right,
      &:nth-child(3n) {
        &.item--extra {
          &::before {
            right: auto;
            left: $gap-medium;
          }
        }

        &.item--extra .item__wrapper {
          padding: $padding-small $padding-small $padding-small $padding-large;
        }
      }
    }

    @include media('>=full') {

      &:nth-child(3n - 2) {
        &.item--extra .item__wrapper {
          padding: $padding-medium;
          transform: translate(-$padding-medium, 0);
        }

        &.item--extra {
          &::before {
            right: 3 * $gap-medium;
            left: auto;
          }
        }
      }

      &.item--right,
      &:nth-child(3n) {
        &.item--extra .item__wrapper {
          padding: $padding-medium;
          transform: translate($padding-medium, 0);
        }

        &.item--extra {
          &::before {
            right: auto;
            left: 3 * $gap-medium;
          }
        }
      }
    }

    &__wrapper {
      padding: $padding-small 0;
    }

    &__cat {
      color: map-get($gray, 'medium');
    }

    &__title {
      margin: 10px 0;
      text-transform: uppercase;
      font: {
        family: $font-secondary;
        weight: 600;
      }

      @include media('>=medium') {
        font-size: rem(18px);
      }
    }

    &__image {
      .image {
        width: 100%;
      }
    }

    &__cta {
      padding: $padding-small 0;
    }

    &--extra {
      display: none;
      min-height: 500px;

      @include media('>=medium') {
        display: block;
      }

      &::before {
        content: '';
        position: absolute;
        top: $gap-xsmall;
        right: $gap-xsmall;
        width: 100vw;
        height: calc(100% - 2 * #{$gap-xsmall});
        background-color: map-get($gray, 'xlight');
        //z-index: -1;

        @include media('>=large') {
          top: $gap-medium;
          right: $gap-medium;
          height: calc(100% - 2 * #{$gap-medium});
        }
      }

      .item__extra-wrapper {
        position: absolute;
        top: 50%;
        right: 0;
        left: 0;
        max-width: 100%;
        padding: inherit;
        transform: translate(0, -50%);
      }

      .item__wrapper {
        overflow: auto;
        padding: $padding-large $padding-large $padding-large 0;
        z-index: 0;

        scrollbar-width: thin;
        scrollbar-color: $color-primary transparent;

        &::-webkit-scrollbar {
          width: 6px;
        }

        &::-webkit-scrollbar-track {
          background: transparent;
        }

        &::-webkit-scrollbar-thumb {
          background-color: $color-primary;
        }
      }

      h2, h3, h4, h5, h6 {
        margin: 20px 0;
        text-transform: none;
        font: {
          family: $font-secondary;
          size: rem(16px);
          weight: 600;
        };
      }

      .item__extract,
      .item__text {
        line-height: $line-height;
      }

      .item__text {
        height: 0;
        overflow: hidden;
      }
    }
  }
}

.products {
  .item {
    @include media('>=medium', '<large') {
      &.item--right,
      &:nth-child(2n) {
        margin-top: 100px;
        margin-bottom: -100px;
      }
    }
  }

  .item__image {
    position: relative;
    height: 0;
    overflow: hidden;
    padding-top: 100%;
    background-color: map-get($gray, xlight);

    &:hover {
      .image {
        transform: scale($image-scale-hover);
      }
    }

    .item__picture {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
    }

    .image {
      transition: transform $scale-duration $ease-out;
    }
  }

  .aubade-creation {
    position: absolute;
    right: 10px;
    bottom: 10px;
    width: 60px;
    height: auto;

    @include media('>=large') {
      width: 75px;
    }

    @include media('>=xlarge') {
      right: 30px;
      bottom: 30px;
    }
  }
}

.products-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,.5);

  .item {

    &--right {
      .svg-icon {
        right: auto;
        left: -2 * $padding-small;
      }
    }
    .svg-icon {
      position: absolute;
      top: 2 * $padding-small;
      width: $padding-small;
      cursor: pointer;
      z-index: 1;
      fill: map-get($gray, 'xlight');
      pointer-events: all;

      @include media('>=large') {
        top: 3 * $padding-small;
        right: -$padding-small;
      }

      @include media('>=full') {
        right: $padding-small;
      }
    }
  }
}
