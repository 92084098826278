&-white-box {
  margin: {
    top: 40px;
    bottom: 60px;
  }

  @include media('>=medium') {
    display: flex;
    justify-content: space-between;
    margin: {
      top: 60px;
      bottom: 100px;
    }
  }

  .item__wrapper {
    padding: 40px;
    border-bottom: 2px solid $color-primary;
    background-color: $white;

    @include media('>=large') {
      padding: 50px;
    }

    @include media('>xlarge') {
      position: relative;
      width: 80%;
      margin: {
        top: -90px;
        right: auto;
        left: auto;
      }
      z-index: 1;
    }
  }

  .item {
    margin: {
      top: 40px;
      bottom: 40px;
    }

    @include media('>=medium') {
      width: calc(50% - #{$gap-medium / 2}); //IE10
      flex-basis: auto;
      flex-grow: 0;
      flex-shrink: 0;
    }

    &__picture {
      position: relative;
      height: 0;
      overflow: hidden;
      padding-top: 77%;
      background-color: map-get($gray, xlight);

      .image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: auto;
      }
    }

    &__title {
      margin: {
        top: 0;
        bottom: 15px;
      }
      text-transform: uppercase;
      font: {
        size: rem(24px);
      }

      @include media('>=xlarge') {
        margin: {
          bottom: 5px;
        }
      }

      a {
        color: inherit;
      }
    }

    &__text {
      margin: {
        top: 15px;
        bottom: 30px;
      }

      @include media('>=xlarge') {
        margin: {
          top: 5px;
        }

        p {
          margin: {
            top: 0;
          }
        }
      }
    }

    &__cta {
      margin: {
        top: 30px;
        bottom: 0;
      }
      text-align: center;

      @include media('>=large') {
        text-align: left;
      }

      .button {
        width: 100%;

        @include media('>=large') {
          width: auto;
        }
      }
    }
  }
}
