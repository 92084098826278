&__menus {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow: auto;
  padding: map-deep($header, 'small', 'height') $padding-small 0;
  background-color: $white;
  z-index: -1;
  transform: translate(-100%, 0);

  @include media('>=medium') {
    padding: map-deep($header, 'small', 'height') $padding-medium 0;
  }

  @include media('>=large') {
    display: flex;
    position: relative;
    width: auto;
    height: auto;
    overflow: visible;
    flex-direction: column-reverse;
    padding: 0 $padding-medium;
    visibility: visible;
    opacity: 1;
    transform: none;
  }
}
