.find-pro {
  display: flex;
  flex-flow: row wrap;
  align-content: center;
  align-items: center;

  &::after {
    content: '';
    width: 0;
    height: 0;
    overflow: hidden;
    clear: both;
    visibility: hidden;
  }

  margin: {
    bottom: 60px;
  };

  @include media('>=medium') {
    margin: {
      bottom: 80px;
    };
  }

  @include media('>=large') {
    display: block;
  }

  .item {
    &__picture {
      display: block;
      position: relative;
      width: 100%;
      height: 0;
      overflow: hidden;
      padding-top: 76.8%;
      background-color: map-get($gray, xlight);

      @include media('>=medium') {
        width: 50%;
        flex-basis: auto;
        padding-top: (76.8% / 2);
      }

      @include media('>=large') {
        float: left;
      }

      .image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: auto;
      }
    }

    &__title {
      width: 100%;
      flex-basis: auto;
      margin: {
        top: 25px;
      }
      line-height: 1.4em;
      color: $color-primary;
      font: {
        family: $font-secondary;
        size: 24px;
        weight: 600;
      }

      @include media('>=medium') {
        width: 50%;
        flex-basis: auto;
        margin: {
          top: 0;
          bottom: 0;
        }
        padding: {
          left: 40px;
        }
        font: {
          size: 26px;
        }
      }
      @include media('>=large') {
        float: right;
      }

      @include media('>=xlarge') {
        padding: {
          top: 5%;
          left: 50px;
        }
        line-height: 1.2em;
        font: {
          size: 36px;
        }
      }

      @include media('>=full') {
        padding: {
          left: 80px;
        }
      }
    }

    &__link {
      color: inherit;
    }

    &__content {
      width: 100%;
      flex-basis: auto;

      @include media('>=medium') {
        margin: {
          top: 25px;
        };
      }
      @include media('>=large') {
        width: 50%;
        flex-basis: auto;
        float: right;
        padding: {
          left: 40px;
        }
      }
      @include media('>=xlarge') {
        padding: {
          bottom: 5%;
        }
        padding: {
          left: 50px;
        }
      }
      @include media('>=full') {
        padding: {
          left: 80px;
        }
      }
    }

    &__text {
      p {
        @include media('>=medium') {
          //margin-top: 0;
        }
      }
    }

    &__cta {
      .button {
        width: 100%;
      }

      @include media('>=medium') {
        margin-top: 30px;

        .button {
          width: auto;
        }
      }
    }
  }

  .image {
    position: relative;
    width: 100%;
    margin-top: 0;
    z-index: 2;
    transition: transform $scale-duration $ease-out;

    @include media('>=medium') {
      margin-top: 0;
    }
  }

  &.block-link:hover {
    .image {
      transform: scale($image-scale-hover);
    }
  }
}
