//HP
&--supports {
  .section__header--center {
    margin: {
      top: 60px;
      bottom: 35px;
    };
    text-align: left;

    @include media('>=medium') {
      margin: {
        top: 60px;
        bottom: 0;
      };
    }

    @include media('>=large') {
      text-align: center;
    }
  }

  .section__header {
    @include media('>=large') {
      width: 75%;
    }
  }

  .contents--bottom-white-box {
    @include media('>=medium') {
      margin: {
        top: 0;
        bottom: 60px;
      }
    }
  }
}
