&--store,
&--product {

  .page__header {
    @include media('>=medium') {
      margin-bottom: 20px;
    }

    @include media('>=large') {
      margin-bottom: 70px;
    }
  }

  .page__buttons {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    margin: {
      top: 25px;
      right: -5px;
      bottom: 25px;
      left: -5px;
    }

    @include media('>=small') {
      display: inline-flex;
      flex-wrap: wrap;
    }

    .button {
      flex: 1 0 auto;
      margin: {
        top: 5px;
        right: 5px;
        bottom: 5px;
        left: 5px;
      }
      white-space: nowrap;

      &--virtual {
        flex: 0 0 auto;
        font-size: 0;

        @include media('>=small') {
          font-size: rem(14px);

          .svg-icon {
            display: none;
          }
        }
      }

      @include media('>=small') {
        width: auto;
        flex: 0 0 auto;
      }
    }

    &--advanced-appointment {
      display: block;

      @include media('>=small') {
        display: flex;
        flex-flow: column nowrap;
        align-items: start;
      }

      .button {
        width: calc(100% - 10px);
        max-width: 340px;

        &:hover {
          .button-logo {
            display: none;

            &--hover {
              display: block;
            }
          }
        }

        &-label {
          display: block;
          max-width: 340px;
          margin: {
            right: 5px;
            bottom: 10px;
            left: 5px;
          }
          font: {
            size: rem(14px);
            weight: bold;
          }

          @include media('>=small') {
            max-width: 340px;
          }

          &--2 {
            margin-top: 20px;
          }
        }

        &-logo {
          width: auto;
          height: 24px;
          margin-left: 10px;

          &--hover {
            display: none;
          }
        }
      }
    }
  }

  .page__thumbnail {
    position: relative;

    &::before {
      content: '';
      position: absolute;
      bottom: -50px;
      left: 0;
      width: 100%;
      height: calc(50% + 50px);
      background-color: map-get($gray, 'xlight');
    }

    .image {
      margin: 0;
    }
  }
}

&--store {
  .page__title,
  .page__desc {
    @include media('>=medium') {
      display: block;
      width: 50%;
    }
  }
  .page__header {
    .message-appointment {
      padding: 5px;
      font-weight: bold;

      @include media('>=medium') {
        width: 50%;
      }

      a {
        display: block;
        color: map-get($blue, primary);
        font-weight: 600;
      }
    }
  }

  .page__title {
    .title {
      display: inline-block;
      padding: inherit;
      margin: inherit;
      font-size: inherit;
      font-weight: inherit;
      font-family: inherit;
      color: inherit;
    }
  }

  .page__buttons {
    @include media('>=medium') {
      width: 50%;
    }
  }

  .page__thumbnail {
    @include media('<medium') {
      .page__picture {
        position: relative;
        height: 0;
        overflow: hidden;
        padding-top: 100%;
        background-color: map-get($gray, xlight);
        transition: transform $scale-duration $ease-out;

        .image {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: auto;
        }
      }
    }

    @include media('>=medium') {
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 1;

      &::before {
        content: none;
      }

      .image {
        position: absolute;
        top: 20px;
        right: 50px;
        width: 40%;
      }
    }
  }
}
