&--project-sdb {
  margin: {
    bottom: 60px;
  };

  .page__title,
  .page__desc {
    @include media('>=small') {
      text-align: center;
    }
  }

  .page__title {
    @include media('>=medium') {
      margin: {
        top: 60px;
      };
    }
  }

  .page__background {
    display: block;
  }

  .accordion--navigation {
    margin: {
      top: 17px;
    }

    @include media('>=small') {
      font-size: rem(13px);
    }
  }

  .page__picture {
    position: relative;
    height: 0;
    overflow: hidden;
    margin: 50px 0;
    padding-top: 68.2%;
    background-color: map-get($gray, xlight);

    .image {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: auto;
      margin: 0;
    }
  }

  .page__author {
    display: none;
  }

  .contents--catalog {
    margin: {
      bottom: 0;
    }
  }

  .page__meta-bottom {
    padding: {
      top: 85px;
      bottom: 85px;
    }
  }

  .page__share {
    text-align: left;

    .sharing-links__legend {
      padding-right: 20px;
      color: $color-text;
      font: {
        size: rem(16px);
      };
    }
  }
}
