&--border-icon {
  border: 1px solid $color-primary;
  background-color: $white;

  .item {
    @include media('>=medium') {
      display: flex;
      flex-direction: row-reverse;
    }

    &__wrapper {
      padding: {
        top: 20px;
        right: 40px;
        bottom: 40px;
        left: 40px;
      }

      @include media('>=medium') {
        max-width: 70%;
        flex-basis: 70%;
        flex-grow: 0;
        flex-shrink: 0;
        padding: {
          top: 60px;
          right: 0;
          bottom: 60px;
          left: 60px;
        }
      }
    }

    &__image {

      @include media('>=medium') {
        flex-basis: 30%;
        flex-grow: 0;
        flex-shrink: 0;
      }
      .image {
        max-width: 75px;
        margin: {
          top: 40px;
          right: auto;
          left: auto;
        }

        @include media('>=medium') {
          margin: {
            top: 60px;
            right: 60px;
            left: auto;
          }
        }
      }
    }

    &__title {
      margin: {
        top: 0;
        bottom: 20px;
      }
      text-align: center;

      @include media('>=medium') {
        margin: {
          top: 0;
          bottom: 15px;
        }
        text-align: left;
        font: {
          size: rem(24px);
        }
      }
    }

    &__text {
      margin: {
        top: 20px;
        bottom: 25px;
      }
      text-align: center;

      @include media('>=medium') {
        margin: {
          top: 15px;
        }
        text-align: left;
      }
    }

    &__cta {
      margin: {
        top: 25px;
      }
    }
  }

  .button {
    width: 100%;

    @include media('>=medium') {
      width: auto;
    }
  }
}
