.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: $white;
  z-index: 100;

  &::before {
    content: '';
    position: absolute;
    top: 100%;
    left: 0;
    width: 100vw;
    height: calc(100vh - 100%);
    background-color: map-get($gray, 'dark');
    visibility: hidden;
    opacity: 0;
    transition: opacity $duration $ease, visibility $duration $ease;
  }

  &--overlay {
    &::before {
      visibility: visible;
      opacity: .1;
    }
  }

  .svg-icon {
    width: 18px;
    height: 18px;

    @include media('>=medium') {
      width: 25px;
      height: 25px;
    }

    &.svg-icon--arrow-right {
      width: 15px;
      height: 15px;
    }

    &.svg-icon--search {
      @include media('>=medium') {
        width: 18px;
        height: 18px;
      }

      @include media('>=large') {
        width: 25px;
        height: 25px;
      }
    }
  }

  @import 'menu';
  @import 'header/wrapper';
  @import 'header/logo';
  @import 'header/tools';
  @import 'header/menus';
  @import 'header/quick-menus';
  @import 'header/links';
}
