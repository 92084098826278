&-legal {
  text-align: center;
  text-transform: uppercase;
  font: {
    family: $font-secondary;
    size: rem(10px);
    weight: 600;
  }

  @include media('>=medium') {
    display: flex;
    align-items: center;
    font-size: rem(11px);
  }

  &__list {
    display: flex;
    flex-flow: column wrap;

    @include media('>=medium') {
      display: inline-flex;
      flex-flow: row;
      text-align: center;
      transform: translateX(-20px);
    }
  }

  &__item {
    position: relative;
    margin: {
      top: 13px;
      bottom: 13px;
    }

    @include media('>=medium') {
      margin: {
        right: 20px;
        left: 20px;
      }

      &::after {
        content: '';
        display: block;
        position: absolute;
        top: .5em;
        right: -20px;
        width: 10px;
        height: 1px;
        background-color: $color-text;
        transform: translate(50%, -50%);
      }

      &:last-child::after {
        content: none;
      }
    }
  }

  &__link {

  }
}
