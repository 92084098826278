&--hp-push-catalog {
  overflow: visible;

  .contents {
    .item {
      @include media('>=large') {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      &__image {
        position: relative;
        height: 0;
        overflow: hidden;
        order: 2;
        margin-bottom: -40px;
        padding-top: 114.28%;
        transform: translateY(-40px);


        @include media('>=large') {
          width: calc(50% - 50px);
          max-width: 600px;
          margin-bottom: -140px;
          padding-top: 57.14%;
        }

        .image {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
        }
      }

      &__wrapper {
        order: 1;
        padding: {
          top: 40px;
          bottom: 40px;
        };

        @include media('>=large') {
          width: 50%;
        }
      }

      &__suptitle {
        margin: {
          top: 0;
          bottom: 20px;
        }
        color: map-get($gray, dark);
        font: {
          family: $font-secondary;
          size: rem(24px);
          weight: 600;
        }
      }

      &__title {
        margin: {
          top: 0;
          bottom: 20px;
        }
        font: {
          family: $font-secondary;
          size: rem(36px);
          weight: 600;
        }
      }

      &__text {

      }

      &__cta {
        .button {
          margin-left: 20px;

          &:first-child {
            margin-left: 0;
          }
        }
      }
    }

  }
}
