&-shift {
  @include media('>=medium') {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    margin: {
      top: 80px;
      bottom: 80px;
    }
    padding-bottom: 100px;
  }

  .item {
    margin: {
      top: 60px;
      bottom: 60px;
    }

    @include media('>=medium') {
      width: calc(50% - 10px); //IE10
      flex-basis: auto;
      flex-grow: 0;
      flex-shrink: 0;
      margin: {
        top: 0;
        bottom: 80px;
      }

      &:nth-child(2n + 2) {
        margin: {
          top: 80px;
          bottom: -80px;
        }
      }
    }

    @include media('>=large') {
      //max-width: calc(50% - 20px);
      //flex: 0 0 calc(50% - 20px);
      width: calc(50% - 20px); //IE10
      max-width: calc(50% - 20px); //IE10
      flex-basis: auto;
      flex-grow: 0;
      flex-shrink: 0;
    }

    @include media('>=xlarge') {
      //max-width: calc(50% - 30px);
      //flex: 0 0 calc(50% - 30px);
      width: calc(50% - 30px); //IE10
      max-width: calc(50% - 30px); //IE10
      flex-basis: auto;
      flex-grow: 0;
      flex-shrink: 0;
      margin: {
        top: 0;
        bottom: 100px;
      }

      &:nth-child(2n + 2) {
        margin: {
          top: 100px;
          bottom: -100px;
        }
      }
    }

    &:hover {
      .item__image {
        .item__picture {
          transform: scale($image-scale-hover);
        }
      }
    }

    &__picture {
      transition: transform $scale-duration $ease-out;
    }

    &__image {
      width: 100%;
      overflow: hidden;
    }

    &__info {
      margin: {
        top: 20px;
        bottom: 15px;
      }
      line-height: 42px;
      letter-spacing: 0.5px;

      @include media('>=medium') {
        margin: {
          top: 30px;
          bottom: 20px;
        }
        font-weight: normal;
      }
    }

    &__title {
      margin: {
        top: 15px;
        bottom: 25px;
      }
      line-height: 24px;
      letter-spacing: 1px;

      @include media('>=medium') {
        margin: {
          top: 20px;
        }
      }

      a {
        color: inherit;
      }
    }

    &__text {
      display: none;

      margin: {
        top: 30px;
        bottom: 25px;
      }

      @include media('>=medium') {
        display: block;
        letter-spacing: 0.1px;
        line-height: 24px;

        margin: {
          top: 30px;
          bottom: 30px;
        }
      }

      @include media('>=xlarge') {
        margin: {
          top: 30px;
          bottom: 25px;
        }
      }
    }

    &__cta {
      margin: {
        top: 25px;
      }

      @include media('>=large') {
        margin: {
          top: 30px;
        }
      }

      @include media('>=xlarge') {
        margin: {
          top: 25px;
        }
      }
    }
  }
}
