&--inner-v {
  position: relative;
  margin-bottom: 0;
  z-index: 2;

  @include media('>=large') {
    position: absolute;
    right: $padding-large;
    bottom: 0;
    width: calc(40.26% - #{$padding-large});
    margin: 0;
    z-index: 2;
  }

  @include media('>=xlarge') {
    width: 30.3%;
  }

  .item {
    &__wrapper {
      @include media('>=medium') {
        min-width: 300px;
      }

      @include media('>=large') {
        max-width: calc(100% - 30px);
      }
    }

    &__picture {
      @include media('>=large') {
        padding-top: 146.7%;
      }
    }
  }
}
