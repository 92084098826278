// See _store.scss
&--product {
  .page__thumbnail {
    @include media('<large') {
      .page__picture {
        position: relative;
        height: 0;
        overflow: hidden;
        padding-top: 68%;
        background-color: map-get($gray, xlight);
        transition: transform $scale-duration $ease-out;

        .image {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: auto;
        }
      }
    }

    @include media('>=large') {
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 1;

      &::before {
        content: none;
      }

      .image {
        position: absolute;
        top: 20px;
        right: 50px;
        width: 40%;
      }
    }
  }

  .page__title,
  .page__desc,
  .page__buttons {
    @include media('>=large') {
      display: block;
      width: 50%;
    }
  }
}

.product-quote-container {
  iframe {
    width: 100%;
    height: 65px;
    overflow: hidden;
    margin: 5px;
  }

  .button {
    max-width: 313px;
  }
}
