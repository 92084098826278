&-light {
  position: relative;

  //@include media('>=medium') {
  //  margin: {
  //    top: 100px;
  //    bottom: 100px;
  //  }
  //}
  //
  //@include media('>=xlarge') {
  //  margin: {
  //    top: 150px;
  //    bottom: 150px;
  //  }
  //}

  .item {
    margin: {
      top: 40px;
      bottom: 40px;
    }

    @include media('>=medium') {
      margin: {
        top: 60px;
        bottom: 60px;
      }
      padding: {
        top: 60px;
        bottom: 60px;
      }
    }

    @include media('>=xlarge') {
      margin: {
        top: 100px;
        bottom: 100px;
      }
      padding: {
        top: 60px;
        bottom: 60px;
      }
    }

    &::before {
      @include media('>=medium') {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        left: calc(50% - 100px);
        width: 100vw;
        background-color: map-get($gray, xlight);
        z-index: 0;
      }
    }

    &:nth-child(2n + 2) {
      &::before {
        @include media('>=medium') {
          right: calc(50% - 100px);
          left: auto;
        }
      }

      .item__image {
        @include media('>=medium') {
          order: 2;
        }
      }

      .item__wrapper {
        @include media('>=medium') {
          padding: {
            top: 0;
            right: 40px;
            bottom: 0;
            left: 0;
          }
        }

        @include media('>=xlarge') {
          padding: {
            top: 0;
            right: 100px;
            bottom: 0;
            left: 0;
          }
        }
      }
    }

    .item__wrapper {
      @include media('>=medium') {
        flex-basis: 50%;
        flex-grow: 0;
        flex-shrink: 0;
        padding: {
          top: 0;
          right: 0;
          bottom: 0;
          left: 40px;
        }
      }

      @include media('>=xlarge') {
        padding: {
          top: 0;
          right: 0;
          bottom: 0;
          left: 100px;
        }
      }
    }

    &__image {
      position: relative;
      z-index: 1;

      @include media('>=medium') {
        flex-basis: 50%;
        flex-grow: 0;
        flex-shrink: 0;
      }
    }

    &__title {
      margin: {
        top: 40px;
      }

      @include media('>=medium') {
        margin: {
          top: 0;
        }
        font: {
          size: rem(24px);
        }
      }

      @include media('>=large') {
        font: {
          size: rem(36px);
        }
      }

      @include media('>=xlarge') {
        margin: {
          bottom: 25px;
        }
        font: {
          size: rem(36px);
        }
      }
    }

    &__text {
      display: block;
      margin: {
        top: 25px;
        bottom: 20px;
      }

      @include media('>=medium') {
        margin: {
          bottom: 30px;
        }
      }
    }

    &__list {
      margin: {
        top: 20px;
        bottom: 40px;
      }

      @include media('>=medium') {
        margin: {
          top: 30px;
        }
      }
    }

    .cta {
      &__item {
        margin: {
          top: 20px;
          bottom: 20px;
        };
      }
    }

    &__cta {
      margin: {
        top: 40px;
        bottom: 10px;
      }

      .button {
        width: 100%;

        @include media('>=large') {
          width: auto;
        }
      }
    }
  }

  @import 'side-light-shift';
}
