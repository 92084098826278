.sharing-links {
  display: inline-flex;
  flex-wrap: wrap;
  align-items: center;

  &__legend {
    padding-right: $padding-medium;
    color: map-get($gray, 'medium');
  }

  &__list {
    display: inline-flex;
    flex-wrap: wrap;
    margin: 0 (- $padding-small);
  }

  &__item {

    &:before {
      content: none;
    }
  }

  &__link {
    display: block;
    padding: $padding-small;
    cursor: pointer;

    &:hover {

      .svg-icon {
        fill: $color-primary;
      }
    }

    .svg-icon {
      fill: map-get($gray, 'medium');
    }
  }
}
