&--cdf-operation {
  overflow: visible;

  @include media('>=large') {
    margin: {
      top: -23px;
    }
  }

  .cdf-operation-block {
    position: relative;
    margin: {
      bottom: 40px;
    }
    padding: {
      top: 20px;
      right: 15px;
      bottom: 20px;
      left: 15px;
    }
    @include media('>=medium') {
      padding: {
        right: 40px;
        left: 40px;
      }
    }

    @include media('>=xlarge') {
      margin: {
        bottom: 160px;
      }
      padding: {
        top: 80px;
        right: 40px;
        bottom: 80px;
        left: 40px;
      }
    }

    &:after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: map-get($gray, xlight);
      z-index: 1;

      @include media('>=xlarge') {
        right: 200px;
        left: -100vw;
      }
    }
  }

  .item {
    &__content {
      position: relative;
      z-index: 2;

      @include media('>=xlarge') {
        width: calc(100% - 500px);
      }
    }

    &__ribon {
      position: relative;
      overflow: hidden;
      margin: {
        right: -15px;
        bottom: 30px;
      }
      text-align: right;
      z-index: 2;

      @include media('>=large') {
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        height: 300px;
        margin: 0;
        text-align: center;
      }

      @include media('>=xlarge') {
        right: 200px;
      }
    }

    &__date {
      display: inline-block;
      padding: 10px 15px;
      background-color: map-get($blue, primary);
      color: $white;
      font: {
        family: $font-secondary;
        size: rem(15px);
        weight: bold;
      }

      @include media('>=large') {
        position: absolute;
        top: -40px;
        right: -116px;
        padding: 10px 100px;
        transform: rotate(30deg);
        transform-origin: top left;
      }
    }

    &__title {
      margin: {
        top: 30px;
        bottom: 15px;
      }
      line-height: 1.4em;
      color: $color-primary;
      font: {
        family: $font-secondary;
        size: rem(30px);
        weight: 600;
      }

      @include media('>=medium') {
        font: {
          size: rem(48px);
        }
      }
    }

    &__subtitle {
      margin: 0;
      line-height: 2em;
      font: {
        size: rem(14px);
        weight: bold;
      }
    }

    &__text {
      line-height: 2em;
      font: {
        size: rem(14px);
        weight: normal;
      }

      p {
        margin-top: 0;
      }
    }

    &__steps {
      @include media('>=large') {
        display: flex;
        margin: {
          right: -30px;
          left: -30px;
        }
      }

      .step {
        margin: {
          top: 30px;
        }

        @include media('>=large') {
          margin: {
            right: 30px;
            left: 30px;
          }
        }

        &__suptitle {
          margin: {
            right: 15px;
          }
          color: $color-primary;
          font: {
            family: $font-secondary;
            size: rem(30px);
            weight: bold;
          }

          @include media('>=large') {
            display: block;
            margin: {
              bottom: 15px;
            }
          }
        }

        &__title {
          font: {
            family: $font-secondary;
            size: rem(20px);
            weight: bold;
          }
        }

        &__text {
          margin: {
            top: 10px;
          }

          p {
            margin: {
              top: 0;
            }
          }
        }
      }
    }

    &__scroll {
      display: none;
      position: relative;
      margin-top: 40px;
      padding-left: 10px;
      color: inherit;
      font: {
        family: $font-primary;
        size: 13px;
      }
      z-index: 1;
      transition: padding $duration;

      @include media('>=large') {
        display: inline-block;
        cursor: pointer;
      }

      &::before {
        content: '';
        display: block;
        position: absolute;
        top: -8px;
        left: 0;
        height: 5px;
        border-left: 1px solid $color-text;
        z-index: 4;
        transition: top $duration, height $duration;
      }

      &::after {
        content: '';
        display: block;
        position: absolute;
        top: 2px;
        left: 0;
        height: 15px;
        border-left: 1px solid $color-text;
        z-index: 4;
      }

      &:hover {
        padding-left: 15px;
        color: inherit;

        &::before {
          top: -11px;
          height: 8px;
        }
      }
    }

    &__picture {
      display: none;
      width: 500px;
      background-color: map-get($gray, xxlight);

      @include media('>=xlarge') {
        display: block;
        position: absolute;
        right: -40px;
        bottom: -100px;
        z-index: 4;
      }

      .image {
        display: block;
        width: 100%;
        height: auto;
      }
    }
  }

  .page__title {
    font: {
      size: rem(25px);
    }

    @include media('>=medium') {
      font: {
        size: rem(36px);
      }
    }
  }
}
