&-branding {
  position: relative;
  z-index: 0;

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: -10px;
    width: 10px;
    background-color: $white;
    z-index: 1;

    @include media('>=medium') {
      left: -15px;
      width: 15px;
    }
  }

  &__list {
    display: inline-block;
    position: relative;
    width: auto;
    background-color: $white;
  }

  &__item {
    display: inline-block;
    width: 100px;
    height: 32px;
    margin: {
      top: 15px;
      right: 10px;
      bottom: 15px;
      left: 0;
    }

    @include media('>=medium') {
      margin: {
        top: 10px;
        right: 20px;
        bottom: 10px;
        left: 0;
      }
    }
  }

  &__link {

  }

  &__image {
    //display: block;
    //width: auto;
    //width: 85px;
    //height: auto;

    @include media('>=medium') {
      //height: 40px;
    }
  }
}
