.virtual-visit {
  position: relative;
  width: 100%;
  //height: 100%;
  padding-top: 75%;

  @include media('>small') {
    width: 400px;
    height: 380px;
    padding: $padding-small;
  }

  @include media('>=large') {
    height: 600px;
  }

  &__inner {
    display: flex;
    position: absolute;
    top: $padding-small;
    left: $padding-small;
    width: calc(100% - 2 * #{$padding-small});
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 15px;

    @include media('>small') {
      position: relative;
      top: auto;
      left: auto;
      width: auto;
      padding: 70px;
    }
  }

  &__picture {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: $color-primary;
      opacity: .8;
    }
  }

  &__logo {
    .svg-icon {
      width: 70px;
      height: 70px;
      fill: $white;

      @include media('>=large') {
        width: 100px;
        height: 100px;
      }
    }
  }

  &__title {
    text-align: center;
    text-transform: uppercase;
    line-height: rem(26px);
    color: $white;
    font: {
      family: $font-secondary;
      size: rem(16px);
    }

    @include media('>=large') {
      line-height: rem(30px);
      font-size: rem(18px);
    }
  }

  &__logo,
  &__title,
  &__button {
    margin: $padding-small 0;
  }
}
