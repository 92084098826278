&-text {
  .item {
    margin: {
      top: 20px;
      bottom: 20px;
    }

    &:first-child {
      margin-top: 0;
    }

    @include media('>=medium') {
      display: block;
      width: 100%;
      max-width: 100%;
    }

    @include media('>=large') {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: {
        top: 100px;
        bottom: 100px;
      }
    }

    @include media('>=xlarge') {
      align-items: center;
    }

    //&.block-link:hover {
    //  .item__image {
    //    .item__picture {
    //      transform: scale($image-scale-hover);
    //    }
    //  }
    //}

    &:nth-child(2n + 2) {
      .item__image {
        @include media('>=medium') {
          order: 2;
        }
      }

      .item__wrapper {
        @include media('>=large') {
          padding: {
            right: 40px;
            left: 0;
          };
        }
      }
    }

    &__wrapper {
      @include media('>=medium') {
        display: block;
        width: 100%;
        max-width: 100%;
      }

      @include media('>=large') {
        width: 50%;
        max-width: 50%; // IE10
        flex-basis: auto;
        flex-grow: 0;
        flex-shrink: 0;
        padding: {
          right: 0;
          left: 40px;
        };
      }
    }

    &__picture {
      transition: transform $scale-duration $ease-out;
    }

    &__image {
      width: 100%;
      overflow: hidden;

      @include media('>=medium') {
        display: block;
        width: 100%;
        max-width: 100%;
      }

      @include media('>=large') {
        width: 50%;
        max-width: 50%; // IE10
        flex-basis: auto;
        flex-grow: 0;
        flex-shrink: 0;
      }
    }

    &__info {
      margin: {
        bottom: 30px;
      }

      @include media('>=medium') {
        margin: {
          top: 0;
        }
      }
    }

    &__title {
      margin: {
        top: 30px;
        bottom: 25px;
      }
      font: {
        size: rem(24px);
        weight: 600;
      }

      @include media('>=large') {
        margin: {
          top: 0;
        }
        font: {
          size: rem(36px);
        }
      }

      @include media('>=xlarge') {
        margin: {
          bottom: 15px;
        }
      }

      a {
        color: inherit;
      }
    }

    &__text {
      display: block;
      margin: {
        top: 25px;
        bottom: 35px;
      }
      @include media('>=large') {
        margin-bottom: 0;
      }

      @include media('>=xlarge') {
        margin: {
          top: 15px;
        }
      }
    }

    &__cta {
      margin: {
        top: 15px;
      }

      @include media('>=medium') {
        margin: {
          top: 35px;
        }
      }
    }
  }
}
