@use "sass:color";

&-pro {
  &__title {
    text-transform: uppercase;
    color: map-get($gray, medium);
    font: {
      family: $font-primary;
      size: rem(12px);
      weight: normal;
    }

    @include media('>=large') {
      margin-top: -4px;
    }
  }

  &__list {
    display: flex;
    flex-flow: row nowrap;
    align-content: center;
    justify-content: space-between;

    @include media('>=small') {
      max-width: 375px;
    }
    @include media('>=large') {
      height: 145px;
      flex-flow: column nowrap;
    }
  }

  &__item {
    display: flex;
    width: calc(50% - 3px);
    height: 60px;
    flex-basis: calc(50% - 3px);
    align-items: center;
    justify-content: center;
    background-color: $color-primary;
    text-align: center;
    color: $white;
    transition: background-color $duration;

    &:hover {
      background-color: color.adjust($color-primary, $lightness: -10%);
    }

    @include media('>=large') {
      width: 100%;
    }

    strong {
      display: block;
      font: {
        size: rem(11px);
        weight: 600;
      }
    }
  }

  &__item:first-child {
    background-color: map-get($gray, xlight);
  }

  &__link {
    display: flex;
    width: 100%;
    height: 100%;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: $white;

    &:hover {
      color: inherit;
    }
  }

  &__icon {
    display: inline-block;
    max-height: 100%;
    width: 100px;
  }
}
