.load-more {
  margin: {
    top: 60px;
    bottom: 60px;
  }

  @include media('>=medium') {
    display: flex;
    justify-content: space-between;
    margin: {
      top: 100px;
      bottom: 100px;
    }
  }

  @include media('>=large') {
    display: block;
  }

  .button {
    width: 100%;
    margin-top: 20px;

    @include media('>=medium') {
      width: calc(50% - 20px);
      margin-top: 0;
    }

    @include media('>=large') {
      width: auto;
      min-width: 450px;
    }

    &:first-child {
      margin-top: 0;

      @include media('>=large') {
        margin-right: 20px;
      }
    }
  }
}
