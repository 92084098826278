.item {
  position: relative;

  &__wrapper {
    position: relative;
  }

  &__info { // date et/ou category
    display: block;
    margin: {
      top: 20px;
      right: 0;
      bottom: 15px;
      left: 0;
    }
    color: map-get($gray, medium);
    font: {
      family: $font-primary;
      size: rem(13px);
      weight: 600;
    }
  }

  &__picto {
    display: block;
    width: auto;
    height: 20px;
    margin-bottom: 10px;
  }

  &__title {
    margin: {
      top: 30px;
      right: 0;
      bottom: 25px;
      left: 0;
    }
    line-height: 1.4em;
    color: $color-primary;
    font: {
      family: $font-secondary;
      size: rem(18px);
      weight: 600;
    }
  }

  &__text {
    margin: {
      top: 25px;
      right: 0;
      bottom: 15px;
      left: 0;
    }
    color: $color-text;
    font: {
      family: $font-primary;
      size: rem(14px);
      weight: normal;
    }

    ul {
      li {
        margin: {
          top: 10px;
          bottom: 10px;
        }

        a {
          display: block;
          position: relative;
          padding: {
            left: 36px;
          };
          border: 0;
          text-transform: none;
          color: $color-primary;
          font: {
            size: rem(12px);
            weight: 600;
          }
          transition: transform $duration;

          &::before {
            content: '';
            display: block;
            position: absolute;
            top: 50%;
            left: 0;
            width: 20px;
            height: 1px;
            background-color: $color-text;
            transform: translateY(-50%);
            transition: width $duration;
          }

          &:hover {
            color: inherit;
            transform: translateX(5px);

            &::before {
              width: 25px;
              height: 1px;
            }
          }
        }
      }
    }
  }

  &__picture {
    display: block;
    width: 100%;
  }

  &__image {
    .image {
      display: block;
      width: 100%;
    }
  }

  &__cta {
    margin: {
      top: 25px;
      right: 0;
      bottom: 0;
      left: 0;
    }
  }

  &__listing {
    margin: {
      right: 0;
      left: 0;
    }
  }
}
