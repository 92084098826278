h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font: {
    family: $font-primary;
    size: rem(14px);
    weight: normal;
  }
}

p,
blockquote {
  line-height: $line-height;
  color: $color-text;
}

blockquote {
  position: relative;
  margin: 0;
  padding: 0 $padding-medium;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 2px;
    height: 100%;
    background-color: $color-primary;
  }
}

a {
  color: $color-text;
  transition: color $duration-fast $ease;

  &:hover {
    color: $color-primary;
  }
}

.cms-content {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: $padding-small 0;
    font: {
      family: $font-secondary;
      weight: 600;
    }
  }

  h2 {
    margin: rem(30px) 0;
    line-height: rem(30px);
    color: $color-primary;
    font-size: rem(24px);

    @include media('>=medium') {
      margin: rem(40px) 0;
      line-height: rem(42px);
      font-size: rem(36px);
    }
  }

  h3 {
    margin: rem(24px) 0;
    line-height: rem(28px);
    font-size: rem(18px);
  }

  h4 {
    color: map-get($gray, medium);
    font-size: rem(18px);
  }

  h5 {
    color: map-get($gray, medium);
    font-size: rem(16px);
  }

  h6 {
    color: map-get($gray, medium);
    font-size: rem(14px);
  }

  p,
  blockquote {
    margin: 0 0 2.5rem;
  }

  ul {
    li {
      position: relative;
      padding-left: 25px;
      margin-bottom: 1em;

      &::before {
        content: '';
        position: absolute;
        top: 8px;
        left: 0;
        width: 10px;
        height: 1px;
        background-color: map-get($gray, medium);
      }
    }
  }

  table {
    width: 100%;
    margin: 0 0 $padding-small;
    border: 1px solid $color-primary;
    border-collapse: collapse;
    color: $color-text;
    font-family: $font-primary;
    break-inside: avoid-column;

    caption {
      margin: $padding-small;
      font-family: $font-secondary;
    }

    td,
    th {
      padding: $padding-small;
      border: 1px solid $color-primary;
    }

    thead td,
    th {
      padding: $padding-small;
      background: $color-primary;
      text-transform: uppercase;
      line-height: 1;
      letter-spacing: 1px;
      color: $white;
      font-family: $font-secondary;
    }
  }

  a:not(.button) {
    position: relative;
    white-space: nowrap;
    color: $color-primary;

    &:hover {
      &::before {
        width: 100%;
      }
    }

    &::before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 0;
      height: 1px;
      background-color: $color-primary;
      transition: width $duration-fast $ease;
    }
  }
}

.center {
  text-align: center;
}
