&--image {
  width: 100%;
  margin: {
    top: 35px;
    bottom: 60px;
  }
  font: {
    family: $font-secondary;
    weight: bold;
  }

  @include media('>=medium') {
    display: flex;
    justify-content: space-between;
    margin: {
      top: 60px;
      bottom: 60px;
    }
  }

  @include media('>=large') {
    margin: {
      top: 45px;
      bottom: 100px;
    }
  }

  @include media('>=xlarge') {
    margin: {
      top: 50px;
      bottom: 120px;
    }
  }

  .item {
    position: relative;
    overflow: hidden;
    margin: {
      top: 15px;
      bottom: 15px;
    }

    @include media('>=medium') {
      width: calc(50% - #{$gap-medium / 2}); //IE trick
      flex-basis: auto;
      flex-grow: 0;
      flex-shrink: 0;
      margin: {
        top: 0;
        bottom: 0;
      }
    }

    &:hover {
      .item__picture {
        transform: scale($image-scale-hover);

        &::before {
          transform: scale(2);
        }
      }

      .item__title {
        transform: translateY(-10px);
      }
    }
  }

  .item__picture {
    position: relative;
    transition: transform $scale-duration $ease-out;

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: linear-gradient(0deg, $bg-gradient-bottom-color 0%, $bg-gradient-top-color 100%);
      z-index: 2;
      transition: transform $duration-slow $ease-out;
    }
  }

  .item__title {
    position: absolute;
    bottom: 0;
    left: 0;
    margin: 0;
    padding: 20px;
    color: $white;
    font: {
      family: $font-secondary;
      size: rem(18px);
      weight: 600;
    }
    z-index: 2;
    transition: transform $duration-slow $ease-out;

    @include media('>=medium') {
      font: {
        size: rem(20px);
      }
    }

    @include media('>=large') {
      padding: 30px;
    }

    p {
      margin: 0;
      line-height: 1.4em;
      color: inherit;
      font: {
        family: inherit;
        size: inherit;
        weight: inherit;
      }
    }

    strong {
      display: block;
      text-transform: uppercase;
      line-height: 1.4em;
      font: {
        size: rem(24px);
        weight: inherit;
      }

      @include media('>=medium') {
        font-size: rem(30px);
      }
    }

    a {
      color: inherit;
    }

    span {
      display: block;
    }
  }
}
