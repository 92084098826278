&-light-shift {
  position: relative;
  margin: {
    top: 60px;
    bottom: 120px;
  }

  @include media('>=medium') {
    margin: {
      top: 100px;
      bottom: 150px;
    }
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
    width: 100vw;
    margin-left: -50vw;
    background-color: map-get($gray, xlight);
  }

  .item {
    display: flex;
    flex-flow: column-reverse;
    margin: {
      top: 0;
      bottom: 0;
    }

    @include media('>=medium') {
      flex-flow: row-reverse nowrap;
    }

    &__image {
      overflow: visible;
      margin: {
        top: 0;
        bottom: -60px;
      }

      @include media('>=large') {
        flex-basis: 50%;
        margin: {
          top: 100px;
          bottom: -100px;
        }
        padding: {
          left: 40px;
        };
      }
      @include media('>=xlarge') {
        flex-basis: 45%;
        padding: {
          left: 80px;
        };
      }
    }

    &__wrapper {
      padding: {
        top: 60px;
        right: 60px;
        bottom: 60px;
        left: 0;
      };

      @include media('>=large') {
        flex-basis: 50%;
        padding: {
          top: 100px;
          right: 40px;
          bottom: 90px;
          left: 0;
        };
      }

      @include media('>=xlarge') {
        flex-basis: 55%;
        padding: {
          top: 190px;
          right: 80px;
          bottom: 220px;
          left: 0;
        };
      }
    }

    &__title {
      margin: {
        top: 0;
        bottom: 25px;
      };

      @include media('>=large') {
        margin: {
          top: 0;
          bottom: 30px;
        };
      }
    }

    &__text {
      display: block;
      margin: {
        top: 25px;
        bottom: 15px;
      };

      @include media('>=large') {
        margin: {
          top: 30px;
          bottom: 35px;
        };
      }
    }

    &__cta {
      display: block;
      margin: {
        top: 15px;
        bottom: 0;
      };

      @include media('>=large') {
        margin: {
          top: 35px;
        };
      }
    }
  }
}
