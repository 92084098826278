&--bottom {
  margin: {
    top: 60px;
    right: 0;
    bottom: 60px;
    left: 0;
  }

  @include media('>=large') {
    margin: {
      top: 80px;
      bottom: 80px;
    }
  }

  @include media('>=xlarge') {
    margin: {
      top: 100px;
      bottom: 100px;
    }
  }

  .item {
    &__title {
      font: {
        size: rem(18px);
        weight: bold;
      }
    }
  }

  @import 'bottom-shift';
  @import 'bottom-white-box';
}
