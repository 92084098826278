.advertising {
  position: relative;
  z-index: 1;

  &--floating {

    @include media('>=large') {
      position: absolute;
      right: 100px;
      z-index: 2;

      .advertising__wrapper {
        width: 340px;

        @include media('>=large') {
          padding: 2 * $padding-small;
        }

        &:before {
          opacity: .9;
        }
      }

      .advertising__item {
        @include media('>=large') {
          display: flex !important; // because of slider
          height: 390px;
          align-items: center;
          padding: 2 * $padding-small;
        }

        @include media('>=xlarge') {
          max-height: 422px;
        }

        @include media('>=full') {
          max-height: 450px;
        }
      }

      .advertising__inner {
        width: 100%;
        flex: 0 0 auto;
      }

      .advertising__close {
        display: none;
      }

      .advertising__icon {
        display: block;
        width: 60px;
        margin: {
          right: auto;
          left: auto;
        }

        img {
          width: 100%;
          height: auto;
        }

        svg {
          width: 100%;
          height: auto;
          fill: $white;
        }
      }

      .advertising__title {
        line-height: 1.75rem;
        font-size: rem(14px);
      }

      .advertising__highlight {
        display: block;
        font-size: rem(28px);
      }

      .advertising__title,
      .advertising_description,
      .advertising__button {
        margin: $padding-small * 2 0;
      }
    }
  }

  &__wrapper {
    position: relative;
    margin-bottom: $padding-small;
    padding: 2 * $padding-small 50px;
    z-index: 0;

    @include media('>=small') {
      padding: 2 * $padding-small;
    }

    @include media('>=large') {
      padding: 2 * $padding-small calc(50% - 250px);
    }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: $gradient;
      z-index: -1;
    }
  }

  &__title {
    margin: 0;
    text-align: center;
    text-transform: uppercase;
    line-height: $line-height;
    letter-spacing: 1px;
    color: $white;
    font: {
      family: $font-secondary;
      size: rem(14px);
      weight: 600;
    }
  }

  &__icon {
    display: none;
    margin: $padding-small;

    .svg-icon,
    svg {
      width: 60px;
      height: 60px;
      margin: 0 auto;
      fill: $white;
    }
  }

  &__description {
    text-align: center;
    line-height: $line-height;
    color: $white;

    p {
      color: inherit;
    }
  }

  &__button {
    text-align: center;
  }

  &__close {
    position: absolute;
    top: 10px;
    right: 0;
    padding: $padding-small;
    border: 0;
    background-color: transparent;
    cursor: pointer;
    transition: transform $duration-fast $ease;

    @include media('>=small') {
      right: 10px;
    }

    .svg-icon {
      width: 16px;
      height: 16px;
      fill: $white;
    }

    &:active {
      transform: scale(.95);
    }
  }

  &__prev,
  &__next {
    position: absolute;
    top: calc(50% - 10px - #{$padding-small} / 2);
    left: 0;
    padding: $padding-small;
    border: 0;
    background-color: transparent;
    cursor: pointer;
    z-index: 1;
    transition: transform $duration-fast $ease;

    @include media('>=small') {
      left: 10px;
    }

    @include media('>=large') {
      left: calc(50% - 300px);
    }

    &:active {
      transform: scale(.95);
    }

    .svg-icon {
      fill: $white;
    }
  }

  &__next {
    right: 0;
    left: auto;

    @include media('>=small') {
      right: 10px;
    }

    @include media('>=large') {
      right: calc(50% - 300px);
    }
  }

  .button {
    display: none;

    @include media('>=medium') {
      display: inline-block;
    }
  }
}
