&-large {
  @include media('>=medium') {
    margin: {
      bottom: 80px;
    }
  }

  @include media('>=large') {
    margin: {
      bottom: 100px;
    }
  }

  .item {
    @include media('>=medium') {
      display: block;
      margin: {
        top: 60px;
        bottom: 80px;
      }
    }

    @include media('>=large') {
      display: flex;
      align-items: center;
      justify-content: space-between;

      @include media('>=xlarge') {
        align-items: center;
      }
    }

    &__image {
      @include media('>=medium') {
        width: 100%;
      }

      @include media('>=large') {
        width: 56%;
        flex-basis: auto;
        flex-grow: 0;
        flex-shrink: 0;
      }
    }

    &__wrapper {
      @include media('>=medium') {
        width: 100%;
      }

      @include media('>=large') {
        width: 44%;
        flex-basis: auto;
        flex-grow: 0;
        flex-shrink: 0;

        padding: {
          top: 0;
          right: 0;
          bottom: 0;
          left: 40px;
        };
      }

      @include media('>=xlarge') {
        padding: {
          left: 80px;
        };
      }
    }

    &__info {
      margin: {
        top: 20px;
        bottom: 15px;
      }
      line-height: 1.6em;

      @include media('>=medium') {
        margin: {
          top: 60px;
          bottom: 15px;
        }
        font-weight: normal;
      }

      @include media('>=large') {
        margin: {
          top: 0;
          bottom: 15px;
        }
      }
    }

    &__title {
      margin: {
        top: 15px;
        bottom: 15px;
      }
      line-height: 1.4em;
      font: {
        size: rem(18px);
        weight: bold;
      }

      @include media('>=medium') {
        line-height: 1.6em;
        font-size: rem(24px);
      }
    }

    &__text {
      margin: {
        top: 15px;
        bottom: 30px;
      }

      @include media('>=medium') {
        line-height: 1.4em;
      }
    }

    &__cta {
      margin: {
        top: 25px;
      }
    }
  }
}
