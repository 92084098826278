.breadcrumb {
  position: relative;
  z-index: 50;

  .svg-icon {
    width: 10px;
    height: 10px;
    pointer-events: none;
  }

  &__button-wrapper {
    @include media('>=small') {
      display: none;
    }
  }

  &__button {
    display: inline-flex;
    margin: 0;
    padding: $padding-small 0;
    border: 0;
    text-transform: uppercase;
    color: map-get($gray, 'medium');
    font: {
      family: $font-secondary;
      size: rem(11px);
    }

    .svg-icon {
      margin-right: $padding-small / 2;
    }

    &:hover {
      .svg-icon {
        fill: $color-primary;
      }
    }
  }

  &__list {
    display: none;

    @include media('>=small') {
      display: inline-flex;
    }
  }

  &__item {
    position: relative;
    padding: $padding-small $padding-small / 2 $padding-small 0;

    &::after {
      content: '-';
      padding-left: $padding-small / 2;
      color: map-get($gray, 'medium');
    }

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      &::after {
        content: none;
      }
    }

    &:hover > .breadcrumb__link {
      color: $color-primary;
    }

    &--with-submenu {
      cursor: pointer;
    }
  }

  &__link {
    display: inline-flex;
    text-transform: uppercase;
    color: map-get($gray, 'medium');
    font: {
      family: $font-secondary;
      size: rem(11px);
    }

    .svg-icon {
      margin-left: $padding-small;
    }

    &:hover {
      .svg-icon {
        fill: $color-primary;
      }
    }
  }

  &__sublist {
    position: absolute;
    top: 100%;
    left: 50%;
    width: 270px;
    padding: $padding-medium;
    background-color: map-get($gray, 'xlight');
    visibility: hidden;
    opacity: 0;
    transform: translate(-50%, 0);

    .breadcrumb__item {
      padding: $padding-small 0;

      &::after {
        content: none;
      }
    }

    .breadcrumb__link {

      &--active {
        color: $color-primary;
        font-weight: 600;
      }
    }
  }
}
