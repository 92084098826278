.jumbotron {
  position: relative;
  height: 0;
  overflow: hidden;
  padding-top: 130%;
  background-color: map-get($gray, xlight);
  color: $white;
  font-family: $font-secondary;
  z-index: 1;

  @include media('>=medium') {
    padding-top: 101%;
  }

  @include media('>=large') {
    padding-top: 47.65%;
  }

  @include media('>=xlarge') {
    padding-top: 40.2%;
  }

  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: linear-gradient(90deg, rgba(0, 0, 0, .5) 0%, rgba(0, 0, 0, 0) 100%);
  }

  &-wrapper {
    position: absolute;
    top: 50%;
    left: 0;
    padding: 0 40px;
    z-index: 1;
    transform: translateY(-50%);

    @include media('>=medium') {
      padding: 0 70px;
    }

    @include media('>=xlarge') {
      padding: 0 100px;
    }

  }

  &__logos {
    margin-bottom: 30px;

    @include media('>=medium') {
      margin-bottom: 40px;
    }
  }

  &__logo {
    display: inline-block;
    width: auto;
    height: 36px;
    margin: {
      right: 15px;
      bottom: 10px;
    }

    &:last-child {
      margin-right: 0;
    }

    @include media('>=medium') {
      margin: {
        right: 30px;
        bottom: 10px;
      }
    }
  }

  &__title {
    text-transform: uppercase;
    color: inherit;
    font: {
      size: 35px;
      weight: 900;
    }

    @include media('>=medium') {
      font: {
        size: 50px;
      }
    }
  }

  &__text {
    p {
      margin: {
        top: 20px;
      }
      color: $white;
      font: {
        family: $font-secondary;
        size: rem(18px);
        weight: normal;
      }

      @include media('>=large') {
        margin: {
          top: 15px;
        }
      }
    }

    u {
      display: inline-block;
      position: relative;
      text-decoration: none;

      &:after {
        content: '';
        position: absolute;
        bottom: -1px;
        left: 0;
        width: 100%;
        height: 1px;
        border-top: 1px solid $white;
      }

    }
  }

  &__scroll {
    display: none;
    position: absolute;
    bottom: 40px;
    left: 40px;
    padding-left: 10px;
    color: inherit;
    font: {
      family: $font-primary;
      size: 13px;
    }
    z-index: 1;
    transition: padding $duration;

    @include media('>=medium') {
      display: inline-block;
      left: 70px;
      cursor: pointer;
    }

    @include media('>=large') {
      left: 100px;
    }

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: -8px;
      left: 0;
      height: 5px;
      border-left: 1px solid $white;
      transition: top $duration, height $duration;
    }

    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 2px;
      left: 0;
      height: 15px;
      border-left: 1px solid $white;
    }

    &:hover {
      padding-left: 15px;
      color: inherit;

      &::before {
        top: -11px;
        height: 8px;
      }
    }
  }

  &__picture {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;

    .image {
      display: block;
      width: 100%;
    }
  }
}
