.search {
  position: relative;

  &--open {
    .search__toggle {
      background-color: map-get($gray, 'xlight');

      .svg-icon {

        &--close {
          display: block;
        }

        &--search {
          display: none;
        }
      }
    }
  }

  &__toggle {
    display: none;
    padding: $padding-small;
    border: 0;
    background: transparent;
    cursor: pointer;
    transition: background-color $duration-fast $ease;

    @include media('>=large') {
      display: block;
      margin: 7px 25px 0 0;
      padding: 21px;
    }

    &:hover {
      background-color: $color-primary;

      .svg-icon {
        fill: $white;
      }
    }

    .svg-icon {
      transition: fill $duration-fast $ease;

      &--close {
        display: none;
      }
    }
  }

  &__wrapper {
    @include media('>=large') {
      position: fixed;
      left: 0;
      width: 100%;
      max-height: calc(100vh - 130px);
      overflow: auto;
      visibility: hidden;
    }
  }

  &__form {
    background-color: map-get($gray, 'xlight');

    @include media('>=large') {
      transform: translate(0, -100%);
    }
  }

  &__field {
    position: relative;

    @include media('>=large') {
      max-width: $max-width-medium;
      margin: 0 auto;
    }
  }

  &__label {
    display: none;
  }

  &__input {
    width: 100%;
    padding: ($padding-small - 2) 50px ($padding-small - 2) $padding-small;
    border: 0;
    background-color: transparent;
    line-height: 1.4em;

    @include media('>=large') {
      padding: $padding-medium - 4 $padding-medium;
      font-size: rem(24px);
    }
  }

  &__button {
    position: absolute;
    top: 50%;
    right: 0;
    padding: $padding-small / 2 $padding-small;
    border: 0;
    background-color: transparent;
    cursor: pointer;
    text-transform: uppercase;
    font-size: 0;
    transform: translate(0, -50%);

    @include media('>=large') {
      right: 40px;
      padding: 12px 30px;
      background-color: $color-primary;
      color: $white;
      font-size: rem(14px);
    }

    .svg-icon {
      width: 18px;
      height: 18px;

      @include media('>=large') {
        display: none;
      }
    }
  }
}
