&--inner {
  margin: {
    top: 15px;
    bottom: 15px;
  }

  .item {
    position: relative;
    overflow: hidden;

    &:hover {
      .item__picture {
        transform: scale($image-scale-hover);
      }
    }

    &__wrapper {
      position: absolute;
      right: 10px;
      bottom: 10px;
      left: 10px;
      padding: 20px;
      background-color: $white;

      @include media('>=medium') {
        right: auto;
        bottom: 20px;
        left: 20px;
        max-width: 50%;
        padding: 30px;
      }
    }

    &__picture {
      position: relative;
      height: 0;
      overflow: hidden;
      padding-top: 68%;
      background-color: map-get($gray, xlight);
      transition: transform $scale-duration $ease-out;

      .image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: auto;
      }
    }

    &__info {
      margin: {
        top: 0;
        bottom: 5px;
      }
      text-transform: uppercase;
      letter-spacing: 1px;
      font: {
        family: $font-secondary;
        size: rem(16px);
        weight: 600;
      }

      @include media('>=medium') {
        margin: {
          top: 0;
          bottom: 0;
        }

        font: {
          size: rem(18px);
        }
      }
    }

    &__title {
      margin: {
        top: 5px;
        bottom: 0;
      }
      text-transform: uppercase;
      font: {
        family: $font-secondary;
        size: rem(16px);
      }

      @include media('>=medium') {
        margin: {
          top: 0;
          bottom: 15px;
        }
        font: {
          family: $font-secondary;
          size: rem(18px);
        }
      }
    }

    &__cta {
      display: none;

      @include media('>=medium') {
        display: block;
        margin: {
          top: 15px;
        }
      }
    }
  }
}

@import 'inner-h';
@import 'inner-v';
