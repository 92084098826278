.section {
  width: 100%;
  overflow: hidden;
  margin: 0 auto;

  &--light {
    background-color: map-get($gray, xlight);
  }

  &--white {
    background-color: $white;
  }

  &__header {
    margin: $offset 0;

    &--center {
      margin: $offset auto;
      text-align: center;
    }
  }

  &__title {
    color: $color-primary;
    font: {
      family: $font-secondary;
      size: rem(25px);
      weight: 600;
    }

    @include media('>=medium') {
      font-size: rem(36px);
    }
  }

  &--overflow-visible {
    overflow: visible;
  }

  @import 'section/projects';
  @import 'section/projects-single';
  @import 'section/supports';
  @import 'section/networks';
  @import 'section/hp-push-catalog';
  @import 'section/2-blocs-hv';
}
