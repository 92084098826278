@use "sass:color";

.map {
  position: relative;
  background-color: color.adjust($color-primary, $lightness: 70%);

  .gm-style .gm-style-iw-c {
    border-radius: 0;

    button {
      top: 6px !important;
      right: 6px !important;
    }
  }

  &__inner {
    height: 100vh;

    @include media('>=medium') {
      height: 820px;
    }

    @include media('>=large') {
      height: 650px;
    }
  }

  &-legend,
  &-filters,
  &-options,
  &-infowindow {
    background-color: $white;
    box-shadow: $shadow-regular;
  }

  &-legend {
    position: absolute;
    top: 20px;
    left: 20px;

    @include media('>=medium') {
      top: auto;
      bottom: 20px;
    }

    &__list {
      display: inline-flex;
      padding: 5px $padding-small;
    }

    &__item {
      display: inline-flex;
      align-items: center;
      padding: $padding-small;

      &::before {
        content: '';
        display: block;
        width: 10px;
        height: 10px;
        margin-right: 10px;
        border-radius: 50%;
        background-color: map-get($gray, 'xlight');
      }

      &--gray {
        &::before {
          background-color: map-get($gray, 'dark');
        }
      }

      &--blue {
        &::before {
          background-color: $color-primary;
        }
      }
    }
  }

  &-filters,
  &-infowindow {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    transform: translate(0, 100%);

    &::before {
      content: '';
      position: absolute;
      top: 10px;
      left: calc(50% - 5px);
      width: 20px;
      height: 3px;
      background-color: map-get($gray, 'light');
    }

    &__inner {
      margin-top: $padding-small;

      @include media('>=medium') {
        margin-top: 0;
      }
    }
  }

  &-filters {
    padding: $padding-small;

    @include media('>=medium') {
      bottom: 10px;
      left: 10px;
      width: calc(100% - 2 * 10px);
      padding: $padding-small 2 * $padding-small;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      transform: none;

      &::before {
        content: none;
      }
    }

    @include media('>=large') {
      width: auto;
      max-width: 340px;
      padding: $padding-medium;
    }

    &__title {
      display: inline-block;
      text-transform: uppercase;
      font: {
        size: rem(18px);
        weight: 600;
      }

      @include media('>=medium') {
        display: block;
        padding: $padding-small 0;
      }
    }

    &__counter {
      color: map-get($gray, 'medium');
    }

    img {
      height: 20px;
      padding-left: 5px;
    }

    .form__wrapper {
      @include media('>=medium', '<large') {
        display: flex;
        flex-wrap: wrap;
      }
    }

    .form__choice {
      @include media('>=medium', '<large') {
        max-width: calc(100% / 3);
        flex-basis: calc(100% / 3);
        margin: 4px 0;
      }

      &:last-child {
        @include media('>=medium', '<large') {
          max-width: 100%;
          flex-basis: 100%;
        }
      }
    }

    .form__separator {
      @include media('>=medium', '<large') {
        margin: 5px 0;
      }
    }
  }

  &-infowindow {

    @include media('>=medium') {
      display: none;
    }
  }

  &-options {
    position: absolute;
    top: 20px;
    right: 20px;

    &--desktop {
      opacity: 0;
      @include media('>=medium') {
        opacity: 1;
      }
    }

    &__list {
      display: inline-flex;
    }

    &__item {

      &--list {
        display: none;
        @include media('>=medium') {
          display: block;
        }
      }

      &--filters {
        @include media('>=medium') {
          display: none;
        }
      }
    }

    &__link {
      display: inline-flex;
      align-items: center;
      padding: 20px 30px;
      text-transform: uppercase;
      letter-spacing: .5px;
      font-weight: 600;

      .svg-icon {
        width: 10px;
        height: 10px;
        margin-right: 10px;
      }

      &:hover {

        .svg-icon {
          fill: $color-primary;
        }
      }
    }
  }
}
