.related-theme {
  padding: {
    top: 60px;
  }

  @include media('>=medium') {
    padding: {
      top: 100px;
    }
  }

  &__title {
    color: $color-primary;
    font: {
      size: rem(18px);
      weight: 600;
    };

    @include media('>=medium') {
      font: {
        size: rem(36px);
      };
    }
  }
}
