.contents {
  position: relative;
  margin: {
    top: 60px;
    right: 0;
    bottom: 60px;
    left: 0;
  }

  @import 'contents/item';
  @import 'contents/image';
  @import 'contents/3-images';
  @import 'contents/bottom';
  @import 'contents/side';
  @import 'contents/icon';
  @import 'contents/catalog';
  @import 'contents/grid';
  @import 'contents/border-icon';
  @import 'contents/list-shift';
  @import 'contents/inner';
  @import 'contents/shift';
}

@import 'contents/rdv';
@import 'contents/find-pro';
