// Same as
// - page--services-list
// - page--categories
// - page--projects-list (sdb, chauffage)

&--categories {
  .breadcrumb__list {
    display: inline-flex;
  }

  .page__header {
    margin: {
      bottom: 0;
    };

    p {
      margin-bottom: 0;
    }
  }

  .contents {
    margin: {
      top: 0;
      bottom: 0;
    };
  }

  .item {
    margin: {
      top: 60px;
      bottom: 60px;
    };

    @include media('>=medium') {
      margin: {
        top: 100px;
        bottom: 100px;
      };
    }

    @include media('>=large') {
      margin: {
        top: 100px;
        bottom: 100px;
      };
    }

    @include media('>=xlarge') {
      margin: {
        top: 150px;
        bottom: 150px;
      };

      &:first-child {
        margin-top: 120px;
      }
    }
  }

  .item__picture {
    position: relative;
    height: 0;
    overflow: hidden;
    padding-top: 76.8%;
    background-color: map-get($gray, xlight);

    .image {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
    }
  }
}
