.status {
  display: inline-flex;
  flex: 0 0 10px;
  align-items: center;
  font: {
    family: $font-primary;
    size: rem(14px);
    weight: 400;
  };

  &::before {
    content: '';
    display: block;
    width: 10px;
    height: 10px;
    margin: 10px;
    border-radius: 50%;
    background-color: map-get($gray, 'xlight');
  }

  &--open {
    color: map-get($color-utils, 'success');

    &::before {
      background-color: map-get($color-utils, 'success');
    }
  }

  &--soon-closed {
    color: map-get($color-utils, 'warning');

    &::before {
      background-color: map-get($color-utils, 'warning');
    }
  }

  &--close {
    color: map-get($color-utils, 'danger');

    &::before {
      background-color: map-get($color-utils, 'danger');
    }
  }

  &--undefined {
    color: map-get($color-utils, 'undefined');

    &::before {
      background-color: map-get($color-utils, 'undefined');
    }
  }

  &--label {
    font-size: rem(14px);
  }
}
