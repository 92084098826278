@import '~normalize.css';
@import 'abstracts/fonts';
@import 'common';
// Layout
@import 'layout/header';
@import 'layout/footer';
@import 'layout/companies-adjustments';
// Components
@import 'components/products';
@import 'components/contact';
@import 'components/rgpd';
@import 'components/locator';
@import 'components/status';
@import 'components/store';
@import 'components/tabs';
@import 'components/gallerie';
@import 'components/sitemap';
@import 'components/more-products';
