&--services {
  .breadcrumb__list {
    display: inline-flex;
  }

  .page__header {
    margin: {
      bottom: 0;
    };

    p {
      margin-bottom: 0;
    }
  }

  .item {
    &__picture {
      position: relative;
      height: 0;
      overflow: hidden;
      padding-top: 77%;
      background-color: map-get($gray, xlight);

      .image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: auto;
      }
    }
  }
  .contents--side-text {
    .item {
      .item__wrapper {
        @include media('>=xlarge') {
          padding-left: 100px;
        }
      }

      &:nth-child(2n + 2) {
        .item__wrapper {
          @include media('>=xlarge') {
            padding: {
              right: 100px;
              left: 0;
            }

          }
        }
      }
    }
  }
  .contents--side {
    .item__text {
      display: block;
    }
  }
}
