.wrapper {
  position: relative;
  width: 100%;
  margin: 0 auto;

  &--large {
    max-width: $max-width-large;
    padding: {
      right: $padding-small;
      left: $padding-small;
    }

    @include media('>=medium') {
      padding: {
        right: $padding-medium;
        left: $padding-medium;
      }
    }
  }

  &--medium {
    max-width: $max-width-medium;
    padding: {
      right: $padding-small;
      left: $padding-small;
    }

    @include media('>=medium') {
      padding: {
        right: $padding-medium;
        left: $padding-medium;
      }
    }
  }

  &--small {
    max-width: $max-width-small;
    padding: {
      right: $padding-small;
      left: $padding-small;
    }

    @include media('>=medium') {
      padding: {
        right: $padding-medium;
        left: $padding-medium;
      }
    }

    @include media('>=large') {
      padding: {
        right: $padding-large;
        left: $padding-large;
      }
    }

    @include media('>=xlarge') {
      padding: {
        right: $padding-large;
        left: $padding-large;
      }
    }
  }

  &--space-between {
    display: flex;
    justify-content: space-between;
  }

  &--full-till-large {
    @include media('<large') {
      padding: {
        right: 0;
        left: 0;
      }
    }
  }

  &--carousel-hp {
    z-index: 1;
  }
}
