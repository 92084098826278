&-right {
  margin: {
    top: 20px;
  }
  text-transform: uppercase;
  line-height: 1.9em;
  font: {
    family: $font-secondary;
    size: rem(9px);
    weight: 600;
  }

  @include media('>=medium') {
    width: 100%;
    margin-top: 0;
  }

  @include media('>=large') {
    width: 25%;
    flex: 0 0 auto;
  }

  @include media('>=xlarge') {
    width: 200px;
  }
}
