&--blog-list {
  margin-bottom: 60px;

  .breadcrumb__list {
    display: inline-flex;
  }

  .page__header {
    margin-bottom: 0;
  }

  .contents--bottom-shift {
    padding-bottom: 0;
  }

  .filters__item--active {
    @include media('<=medium') {
      display: none;
    }
  }

  .contents {
    .item__info {
      font: {
        weight: normal;
      }

      strong {
        font: {
          weight: 600;
        }
      }
    }

    .item {
      &__picture {
        position: relative;
        height: 0;
        overflow: hidden;
        padding-top: 50.72%;
        background-color: map-get($gray, xlight);
        transition: transform $scale-duration $ease-out;

        @include media('>=medium') {
          padding-top: 59.52%;
        }

        .image {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: auto;
        }
      }
    }

    &.contents--side-large {
      .item__picture {
        @include media('>=medium') {
          padding-top: 50.72%;
        }

        @include media('>=large') {
          padding-top: 68%;
        }

        @include media('>=xlarge') {
          padding-top: 59%;
        }
      }
    }
  }

  .popular {
    .item {
      &__picture {
        position: relative;
        height: 0;
        overflow: hidden;
        padding-top: 77%;
        background-color: map-get($gray, xlight);
        transition: transform $scale-duration $ease-out;

        @include media('>=medium') {
          padding-top: 50%;
        }

        @include media('>=medium') {
          padding-top: 50%;
        }

        .image {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: auto;
        }
      }
    }

    .item__title {
      margin: {
        top: 15px;
      }
      text-transform: none;
      color: $color-primary;

      @include media('>=medium') {
        margin: {
          top: 2px;
        }
      }

      a {
        color: inherit;;
      }
    }

    .item__info {
      @include media('>=medium') {
        margin: {
          top: 40px;
        }
      }
    }

    .item__cta {
      margin: {
        top: 30px;
      }
    }
  }

  //.contents {
  //  margin: {
  //    top: 60px;
  //    bottom: 60px;
  //  }
  //
  //  &:first-child {
  //    margin: {
  //      top: 40px;
  //    }
  //  }
  //}
  //
  //.contents--side-large {
  //  display: block;
  //}
}
