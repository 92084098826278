&__logo {
  position: absolute;
  top: 40px;
  left: 50px;
  min-width: 70px;
  height: $logo-height-small;
  z-index: 5;

  @include media('>=medium') {
    top: 28px;
    left: 85px;
  }

  @include media('>=large') {
    top: 17px;
    left: $padding-medium;
  }
}
