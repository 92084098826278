&--grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: {
    top: 60px;
    bottom: 0;
    //bottom: 105px;
  }
  padding: 0;
  //padding: 0 0 $padding-large;

  @include media('>=medium') {
    margin: {
      top: 100px;
      bottom: 100px;
    }
  }

  @include media('>=large') {
    display: grid;
    margin: 90px -10px ($offset + 100px);
    grid-template-rows: repeat(2, 1fr);
    grid-template-columns: repeat(3, 1fr);
  }

  &::before { //graphical grey border
    content: '';
    position: absolute;
    top: -70px;
    right: 15%;
    bottom: 30%;
    left: -100vw;
    border: {
      top: 0;
      right: 10px solid map-get($gray, xlight);
      bottom: 10px solid map-get($gray, xlight);
      left: 0;
    }

    @include media('>=medium') {
      top: -110px;
      right: 20%;
    }

    @include media('>=large') {
      bottom: 32%;
    }

    @include media('>=xlarge') {
      right: 16%;
      bottom: 41%;
    }
  }

  .item {
    width: calc(50% - #{$padding-small} / 2); //IE10
    flex-basis: auto;
    flex-grow: 0;
    flex-shrink: 0;
    margin: $padding-small / 2 0;

    @include media('<large') {
      &:nth-child(2n) {
        transform: translate(0, $offset);
      }
    }

    @include media('>=large') {
      width: 100%;
      margin: 0;
      padding: 10px;
    }

    &.block-link:hover {
      .image {
        transform: scale($image-scale-hover);
      }
    }

    &__wrapper {
      display: inline-flex;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      align-items: flex-end;
      justify-content: space-between;
      padding: 20px;

      @include media('>=large') {
        bottom: 10px;
        left: 10px;
        width: calc(100% - 2 * 10px);
        padding: 2 * $padding-small;
      }
    }

    &__title {
      margin: 0;

      a {
        color: $white;
      }
    }

    &__logo {
      display: none;
      height: 18px;
      padding-left: $padding-small;
      transition: opacity $duration;

      @include media('>=small') {
        display: block;
      }

      &--hidden {
        opacity: 0;
      }
    }

    &__picture {
      position: relative;
      height: 0;
      overflow: hidden;
      padding-top: 142.8%;
      background-color: map-get($gray, xlight);

      &::before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: linear-gradient(0deg, $bg-gradient-bottom-color 0%, $bg-gradient-top-color 50%);
      }

      .image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: auto;
        transition: transform $scale-duration $ease-out;
      }
    }

    &__image.landscape {

      @include media('>=large') {
        .item__picture {
          padding-top: 69%;
        }
      }
    }
  }

  &-5 {
    @include media('>=large') {
      .item {
        &:nth-child(1) {
          grid-area: 1 / 1 / 3 / 2;
        }

        &:nth-child(2) {
          transform: translate(0, $offset);
          grid-area: 1 / 2 / 2 / 3;
        }

        &:nth-child(3) {
          transform: translate(0, $offset);
          grid-area: 2 / 2 / 3 / 3;
        }

        &:nth-child(4) {
          grid-area: 1 / 3 / 2 / 4;
        }

        &:nth-child(5) {
          grid-area: 2 / 3 / 3 / 4;
        }
      }
    }
  }

  &-4 {
    margin-bottom: 160px;

    @include media('>=medium') {
      margin-bottom: 200px;
    }

    @include media('>=large') {
      .item {
        &:nth-child(1) {
          grid-area: 1 / 1 / 3 / 2;
        }

        &:nth-child(2) {
          transform: translate(0, $offset);
          grid-area: 1 / 2 / 3 / 3;
        }

        &:nth-child(3) {
          grid-area: 1 / 3 / 2 / 4;
        }

        &:nth-child(4) {
          grid-area: 2 / 3 / 3 / 4;
        }
      }
    }
  }

  &-3 {
    @include media('>=large') {
      .item {
        &:nth-child(1) {
          grid-area: 1 / 1 / 3 / 2;
        }

        &:nth-child(2) {
          transform: translate(0, $offset);
          grid-area: 1 / 2 / 3 / 3;
        }

        &:nth-child(3) {
          grid-area: 1 / 3 / 3 / 4;
        }
      }
    }
  }
}

