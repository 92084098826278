.sidebar {
  display: none;
  position: fixed;
  top: 50%;
  max-width: rem(300px);
  border: 1px solid map-get($gray, 'xlight');
  background-color: $white;
  font-size: rem(13px);
  z-index: 1;
  transform: translate(calc(-100% + 2 * #{$padding-small} + 10px), -50%);
  backface-visibility: hidden;

  @include media('>=full') {
    display: flex;
  }

  &--open {
    .sidebar__toggle {
      &:after {
        transform: rotate(0deg);
      }
    }

    .sidebar__progress {
      visibility: hidden;
      opacity: 0;
    }
  }

  &__toggle {
    position: absolute;
    top: 0;
    right: 0;
    width: calc(10px + 2 * #{$padding-small});
    height: calc(10px + 2 * #{$padding-small});
    padding: $padding-small;
    border: 0;
    cursor: pointer;
    transition: background-color $duration-fast $ease;

    &:hover {
      &::before,
      &::after {
        background-color: $color-primary;
      }
    }

    &:before,
    &:after {
      content: '';
      position: absolute;
      top: calc(50% - 1px);
      left: $padding-small;
      width: 10px;
      height: 2px;
      background-color: $color-text;
    }

    &:after {
      transform: rotate(-90deg);
      transition: transform $duration-fast $ease;
    }
  }

  &__progress {
    position: absolute;
    top: 15%;
    right: calc(#{$padding-small} + 5px - 1.5px);
    width: 3px;
    height: 70%;
    background-color: map-get($gray, 'xlight');
    transition: visibility $duration-fast $ease, opacity $duration-fast $ease;
  }

  &__progressbar {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 0;
    background-color: $color-primary;
    transform: height $duration $ease;
  }

  &__content {
    padding: $padding-large;
  }

  &__item {
    padding-bottom: $padding-small * 2;

    &:last-child {
      padding-bottom: 0;
    }

    &--active {
      > .sidebar__link {
        color: $color-primary;
        transform: translate($padding-small / 2, 0);
      }
    }
  }

  &__link {
    display: block;
    padding: $padding-small / 2 0;
    text-transform: uppercase;
    line-height: rem(18px);
    font-weight: 600;
    transition: color $duration-fast $ease, transform $duration $ease;
  }

  &__sublist {
    padding-left: $padding-small * 2;
    .sidebar__item {
      padding-bottom: 0;
    }

    .sidebar__link {
      text-transform: none;
      font-weight: 400;
    }
  }
}
