.choices {
  cursor: pointer;

  ~ .form__label {
    display: none;
  }

  &.is-open {
    .choices__inner {
      border: 1px solid map-get($gray, 'light');
    }

    .choices__list {
      &--single {
        &::after {
          transform: rotate(405deg);
        }
      }
    }
  }

  &.is-error {
    .choices__inner,
    .choices__list--dropdown {
      border: 1px solid map-get($color-utils, 'danger');
    }
  }

  &__inner {
    border: 1px solid map-get($gray, 'medium');
    transition: border $duration-fast $ease;
  }

  &__list {

    &--single {
      position: relative;

      &::after {
        content: '';
        position: absolute;
        top: 2.5px;
        right: 0;
        width: 10px;
        height: 10px;
        border-top: 2px solid map-get($gray, 'medium');
        border-left: 2px solid map-get($gray, 'medium');
        transform: rotate(225deg);
        transition: transform $duration-fast $ease;
      }
    }

    &--dropdown {
      position: absolute;
      width: 100%;
      max-height: 25vh;
      margin-top: -1px;
      border: 1px solid map-get($gray, 'light');
      background-color: $white;
      visibility: hidden;
      opacity: 0;
      z-index: 1;
      transition: visbility $duration-fast $ease, opacity $duration-fast $ease;
      overflow-y: auto;

      &.is-active {
        visibility: visible;
        opacity: 1;
      }
    }
  }

  &[data-type*=select-one] {
    .choices__inner {
      padding: $padding-input-top $padding-input-left;
    }
  }

  &__item {

    &--choice {
      padding: $padding-small;
      transition: background-color $duration-fast $ease, color $duration-fast $ease;

      &:hover {
        background-color: $color-primary;
        color: $white;
      }

      &.is-selected {
        background-color: map-get($gray, 'xlight');
        color: $color-text;
      }
    }
  }
}
