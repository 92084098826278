.extra {
  margin-top: $padding-medium;

  @include media('>=medium') {
    display: none;
  }

  .item {

    &__text {
      height: 0;
      overflow: hidden;
    }

    p {
      line-height: rem(20px);
      font-size: rem(11px);
    }

    h2, h3, h4, h5, h6 {
      margin: 20px 0;
      text-transform: none;
      font: {
        family: $font-secondary;
        size: rem(14px);
        weight: 600;
      };
    }
  }
}
