html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

body {
  margin: 0;
  padding: 0;
  color: $color-text;
  font: {
    family: $font-primary;
    size: rem(14px);
  }
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

a {
  cursor: pointer;
  text-decoration: none;
}

select::-ms-expand {
  display: none;
}

input,
button,
textarea {
  border-width: 1px;
  appearance: none;
}

*:focus {
  outline: none;
}

img {
  display: block;
}
