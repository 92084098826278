
.carousel-hp {
  &.loading {
    .carousel-hp__slide {
      display: none;
    }

    .carousel-hp__slide:first-child {
      display: block;
    }
  }

  &__play {
    display: none;
    position: absolute;
    top: 100%;
    right: 0;
    margin: {
      top: 10px;
    }
    padding: 0;
    border: 0;
    background-color: transparent;
    cursor: pointer;
    color: map-get($gray, dark);
    font: {
      size: rem(10px);
    }

    @include media('>=medium') {
      display: block;

      &[data-action='stop'],
      &[data-action='start'] {
        display: block;
      }
    }

    &--label {
      display: inline-block;
      margin: {
        left: 7px;
      }
    }
  }

  &__controls {
    display: inline-flex;
    position: absolute;
    right: $padding-small;
    bottom: 0;
    width: auto;
    z-index: 3;

    @include media('<small') {
      display: flex;
      position: relative;
      right: 0;
      justify-content: flex-end;
    }

    @include media('>=medium') {
      right: $padding-medium;
    }
  }

  &__counter {
    display: block;
    width: 60px;
    height: 60px;
    background-color: map-get($gray, xlight);
    text-align: center;
    line-height: 60px;
    color: map-get($gray, dark);
    font: {
      family: $font-secondary;
      size: rem(14px);
    }
  }

  &__control {
    width: 60px;
    height: 60px;
    padding: 0;
    border: 0;
    background-color: $color-primary;
    cursor: pointer;
    line-height: 60px;

    .svg-icon {
      height: 60px;
      margin: 0 auto;
      fill: $white;
    }

    &:hover {
      background-color: $white;

      .svg-icon {
        fill: $color-primary;
      }
    }
  }

  &__slide {
    position: relative;
  }

  &-wrapper {
    position: absolute;
    top: 50%;
    left: 0;
    padding: 0 40px;
    z-index: 2;
    transform: translateY(-50%);

    @include media('>=medium') {
      padding: 0 70px;
    }

    @include media('>=xlarge') {
      padding: 0 100px;
    }
  }

  &__logo {
    display: block;
    width: auto;
    height: 62px;
    margin-bottom: 30px;

    @include media('>=small') {
      height: 84px;
    }

    @include media('>=medium') {
      margin-bottom: 40px;
    }
  }

  &__title {
    text-transform: uppercase;
    color: $white;
    font: {
      family: $font-secondary;
      size: rem(18px);
      weight: 700;
    }

    @include media('>=medium') {
      font: {
        size: rem(18px);
      }
    }
  }

  &__text {
    margin: {
      top: 20px;
    }
    line-height: 1.7;
    color: $white;
    font: {
      family: $font-secondary;
      size: rem(18px);
      weight: normal;
    }

    @include media('>=large') {
      margin: {
        top: 15px;
      }
    }
  }

  .button {
    margin: {
      top: 27px;
    }
  }

  &__picture {
    display: block;
    position: relative;
    width: 100%;
    height: 0;
    overflow: hidden;
    padding-top: 130%;
    background-color: map-get($gray, xlight);
    z-index: 1;

    @include media('>=medium') {
      padding-top: 101%;
    }

    @include media('>=large') {
      padding-top: 47.65%;
    }

    @include media('>=xlarge') {
      padding-top: 40.2%;
    }

    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: linear-gradient(90deg, rgba(0, 0, 0, .5) 0%, rgba(0, 0, 0, 0) 100%);
    }

    .image {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
    }
  }
}
