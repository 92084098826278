.message {
  display: block;
  width: 100%;
  padding: $padding-small 0;
  color: $color-primary;
  font: {
    family: $font-secondary;
    size: rem(13px);
    weight: 600
  }

  &--success {
    color: map-get($color-utils, 'success');
  }

  &--error {
    color: map-get($color-utils, 'danger');
  }

  &--warning {
    color: map-get($color-utils, 'warning');
  }
}
