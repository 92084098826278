@use "sass:color";

.locator {

  &--map {
    .locator__map {
      visibility: visible;
      opacity: 1;
    }
  }

  &--reduced {

    .locator__list {
      .item {

        &:nth-child(n+4) {
          display: none;
        }
      }
    }
  }

  &__section {
    display: none;
    position: relative;
    flex-direction: column-reverse;
    margin-bottom: $padding-large;

    &--active {
      display: flex;
    }

    @include media('>=medium') {
      display: block;
    }
  }

  &__block-title {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    justify-content: space-between;
    margin: 0 (- $padding-small);
  }

  &__title {
    display: none;
    padding: $padding-small;
    letter-spacing: .5px;
    font: {
      size: rem(16px);
      weight: 600;
    }

    @include media('>=medium') {
      display: block;
    }

    &--uppercase {
      text-transform: uppercase;
    }
  }

  &__highlight {
    color: $color-primary;
  }

  &__more {
    display: block;
    width: 100%;
    padding: $padding-small;
    text-align: center;
    color: $color-primary;
    font-weight: 600;

    @include media('>=medium') {
      display: inline-block;
      width: auto;
      padding: 0 $padding-small $padding-small;
      text-align: left;
    }
  }

  &__map {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    visibility: hidden;
    opacity: 0;
    z-index: 100;
    transition: visibility $duration $ease, opacity $duration $ease;

    @include media('>=medium') {
      position: relative;
      margin: 0 0 $padding-large;
      visibility: visible;
      opacity: 1;
      z-index: 0;
    }

    .item {
      width: 100%;
      font-family: $font-primary;

      @include media('>=medium') {
        width: 380px;
      }

      &__wrapper {
        padding: $padding-large / 2;

        @include media('>=medium') {
          width: 100%;
          padding: $padding-medium;
        }

        @include media('>=large') {
          padding: $padding-large;
        }
      }
    }
  }

  &__back {
    display: inline-flex;
    align-items: center;
    margin-bottom: $padding-small * 2;
    color: $color-primary;
    font-weight: 600;

    .svg-icon {
      width: 10px;
      height: 10px;
      margin-right: 5px;
      fill: $color-primary;
    }

    @include media('>=medium') {
      display: none;
    }
  }

  &__list {
    margin: 0 (- $padding-small);

    @include media('>=medium') {
      display: flex;
      flex-wrap: wrap;
      margin: 0 (-10px);
    }

    @include media('>=full') {
      margin: 0 (- 20px);
    }

    .item {
      padding: $padding-small 0;

      @include media('>=medium') {
        display: flex;
        max-width: 50%;
        flex-basis: 50%;
        padding: 10px;
      }

      @include media('>=large') {
        max-width: calc(100% / 3);
        flex-basis: calc(100% / 3);
      }

      @include media('>=full') {
        padding: 20px;
      }

      &--no-btn {
        .item__actions {
          @include media('>=medium') {
            padding-bottom: 75px;
          }
        }
      }

      &__wrapper {
        padding: $padding-large / 2 $padding-medium;
        background-color: map-get($gray, 'xlight');

        @include media('>=medium') {
          width: 100%;
          padding: $padding-medium;
        }

        @include media('>=large') {
          padding: $padding-large;
        }
      }
    }
  }

  &__list,
  &__map {

    .item {

      &__wrapper {
        position: relative;

        @include media('>=medium') {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        }
      }

      &__categorie {
        display: block;
        width: calc(100% - 75px);
        line-height: $line-height;
        color: map-get($gray, 'medium');

        @include media('>=medium') {
          width: 100%;
        }
      }

      &__title {
        display: flex;
        align-items: flex-start;
        margin: 0;
        text-transform: uppercase;
        line-height: $line-height;
        font: {
          size: rem(18px);
          weight: 600;
        }

        @include media('>=medium') {
          width: 100%;
          margin-bottom: $padding-small * 2;
        }

        &--reduced {
          width: calc(100% - 75px);

          @include media('>=medium') {
            width: 100%;
          }
        }
      }

      &__address {
        line-height: rem(20px);

        @include media('>=medium') {
          flex: 1 0 auto;
        }
      }

      &__actions {
        display: flex;
        flex-direction: row-reverse;
        align-items: flex-end;
        justify-content: space-between;
        margin: $padding-small -2.5px 0;

        @include media('>=medium') {
          flex-direction: column;
          align-items: flex-start;
          margin: 0;
        }
      }

      &__phones {
        display: flex;
        line-height: $line-height;

        @include media('>=medium') {
          flex-direction: column;

          .button {
            @include media('>=medium') {
              padding: 0;
              border: 0;
              text-transform: none;
              letter-spacing: normal;
              color: $color-text;
              font-size: rem(14px);
              font-weight: 400;

              &::before {
                content: none;
              }

              .svg-icon {
                display: none;
              }
            }
          }
        }
      }

      &__discover,
      &__rdv {
        flex: 1 0 auto;

        .button {
          width: 100%;
        }

        .svg-icon {
          display: none;
        }

        @include media('>=medium') {
          flex: 0 0 auto;

          .button {
            width: auto;
          }

          .svg-icon {
            display: block;
          }
        }
      }

      &__phone,
      &__discover,
      &__rdv {
        padding: 2.5px;

        @include media('>=medium') {
          padding: 0;
        }
      }

      &__phones,
      &__discover,
      &__rdv {

        @include media('>=medium') {
          margin: $padding-small / 2 0;
        }
      }

      &__rdv {
        .button--regular {
          @include media('>=large', '<full') {
            padding: 20px;
          }
        }
      }

      &__phones {
        @include media('>=medium') {
          margin: $padding-small 0;
        }
      }

      &__labels {
        position: absolute;
        top: $padding-large / 2;
        right: $padding-medium;

        @include media('>=medium') {
          display: inline-flex;
          position: relative;
          top: auto;
          right: 0;
          align-items: center;
          margin: 0 -10px $padding-small / 2;
        }
      }

      &__label {
        display: flex;
        padding: 0 0 10px;
        font-size: 0;

        img {
          width: 55px;
          height: 100%;
        }

        @include media('>=medium') {
          padding: 10px;
        }
      }

      &__discover {

        .button {
          @include media('>=medium') {
            padding: 0;
            border: 0;
            color: $color-primary;

            &::before {
              content: none;
            }
          }
        }
      }

      &__phone {
        display: none;

        @include media('>=medium') {
          display: block;
        }

        &--mobile {
          display: block;

          @include media('>=medium') {
            display: none;
          }
        }
      }
    }
  }

  &__switcher {
    display: inline-flex;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    justify-content: center;
    padding: $padding-small;
    z-index: 100;
  }

  &-tab {

    @include media('>=medium') {
      display: none;
    }

    &__list {
      display: flex;
      overflow-x: auto;
    }

    &__item {

      &--active {

        .locator-tab__link {
          background-color: color.adjust($color-primary, $lightness: 70%);
          color: $color-primary;
        }
      }
    }

    &__link {
      display: block;
      padding: $padding-small;
      white-space: nowrap;
      color: map-get($gray, 'medium');
      font-weight: 600;
    }
  }

  .button {
    white-space: nowrap;

    @include media('>=medium') {
      white-space: normal;
    }

    &__label {
      display: none;

      @include media('>=medium') {
        display: block;
      }

      &--mobile {
        display: block;

        @include media('>=medium') {
          display: none;
        }
      }
    }
  }
}
