.tns {
  &-nav {
    display: none;
    position: absolute;
    bottom: 15px;
    left: 0;
    width: 100%;
    text-align: center;

    @include media('>=large') {
      display: block;
    }

    button {
      display: inline-flex;
      position: relative;
      width: 10px;
      height: 10px;
      border: 0;
      background-color: transparent;
      &.tns-nav-active {
        &:after {
          opacity: 1;
        }
      }

      &:after {
        content: '';
        position: absolute;
        top: 2.5px;
        left: 2.5px;
        width: 5px;
        height: 5px;
        margin: 0 4px;
        padding: 0;
        border-radius: 50%;
        background-color: $white;
        opacity: .5;
      }
    }
  }

  &-inner {
    .tns-nav {
      display: none;
    }
  }
}

button[data-action='stop'],
button[data-action='start'] {
  display: none;
}
