.tabs {

  &__contents {
    position: relative;
  }

  &__content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    visibility: hidden;
    opacity: 0;
    transition: opacity $duration-fast $ease, visibility $duration-fast $ease;

    &--active {
      visibility: visible;
      opacity: 1;
    }
  }
}

.tabs-nav {
  margin: 0 (- $padding-small);

  &__list {
    display: inline-flex;
  }

  &__item {

    &--active,
    &:hover {

      .tabs-nav__link {
        color: $color-primary;

        &::before {
          background-color: $color-primary;
        }
      }
    }

    &:active {
      .tabs-nav__link {
        transform: scale(.95);
      }
    }
  }

  &__link {
    display: block;
    position: relative;
    padding: $padding-small;
    cursor: pointer;
    text-transform: uppercase;
    color: map-get($gray, 'light');
    font-weight: bold;
    transition: color $duration $ease, transform $duration-fast $ease;

    &::before {
      content: '';
      position: absolute;
      bottom: 0;
      left: $padding-small;
      width: calc(100% - 2 * #{$padding-small});
      height: 2px;
      background-color: map-get($gray, 'light');
      transition: background $duration-fast $ease;
    }
  }
}
