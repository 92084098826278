&-social {
  min-width: 180px;

  &__list {
    display: flex;
    max-width: 180px;
    flex: 0 0 180px;
    justify-content: space-between;
    margin: 15px auto;
    text-align: center;

    @include media('>=medium') {
      margin: {
        left: 0;
      }
    }
  }

  &__link {
    .svg-icon {
      transition: fill $duration;
      fill: map-get($gray, medium);
    }

    &:hover {
      .svg-icon {
        fill: $color-primary;
      }
    }
  }
}
