.footer {
  position: relative;

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 32px;
    width: 100%;
    height: 2px;
    background-color: map-get($gray, xlight);

    @include media('>=medium') {
      top: 25px;
    }
  }

  &__top {
    margin: {
      bottom: 60px;
    }
    background-color: $white;

    @include media('>=medium') {
      margin: {
        bottom: 45px;
      }
    }

    @include media('>=xlarge') {
      margin: {
        bottom: 40px;
      }
    }
  }

  &__main {
    margin: {
      bottom: 30px;
    }
    background-color: $white;

    @include media('>=medium') {
      .footer-wrapper {
        display: flex;
        width: 100%;
        flex-flow: row wrap;
        justify-content: space-between;
      }
    }
  }

  &__bottom {
    margin: {
      top: 30px;
    }
    padding: 24px 0 8px;
    background-color: map-get($gray, xlight);

    @include media('>=large') {
      padding: 0;
    }

    .footer-wrapper {
      @include media('>=large') {
        display: flex;
        align-content: center;
        justify-content: space-between;
      }
    }
  }

  @import 'footer/branding';
  @import 'footer/nav';
  @import 'footer/right';
  @import 'footer/invoice';
  @import 'footer/pro';
  @import 'footer/newsletter';
  @import 'footer/legal';
  @import 'footer/social';
}
