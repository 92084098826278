&--rdv {
  .breadcrumb__list {
    display: inline-flex;
  }

  .page__header {
    margin-bottom: 0;
  }

  .page__title {
    margin: {
      bottom: 25px;
    }

    font: {
      size: rem(30px);
    };

    @include media('>=medium') {
      margin: {
        bottom: 50px;
      }
      font: {
        size: rem(48px);
      };
    }
  }
}
