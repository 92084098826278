&--inner-h {
  position: relative;
  z-index: 2;

  @include media('>=large') {
    width: 59.52%;
    margin: 0;
  }

  @include media('>=xlarge') {
    width: 47.7%;
    margin: {
      right: auto;
      left: 18.46%;
    }
  }

  .item {
    &__wrapper {

      @include media('>=medium') {
        min-width: 300px;
      }

      @include media('>=large') {
        max-width: calc(100% - 60px);
      }
    }
  }
}
