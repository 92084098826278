&--blog {

  .page__title {
    @include media('>=medium') {
      margin-top: 60px;
    }
  }

  .page__title,
  .page__desc {
    @include media('>=small') {
      text-align: center;
    }
  }

  .page__background {
    display: block;
  }

  .page__header {
    margin: {
      bottom: 0;
    }
  }

  .page__thumbnail {
    margin: {
      top: 60px;
      bottom: 60px;
    }

    .page__picture {
      position: relative;
      height: 0;
      overflow: hidden;
      padding-top: 68.24%;
      background-color: map-get($gray, xlight);
      transition: transform $scale-duration $ease-out;

      .image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: auto;
        margin: {
          top: 0;
          bottom: 0;
        }
      }
    }
  }

  .related-theme {
    .item__title {
      margin: {
        top: 15px;
      }
      text-transform: none;
      color: $color-primary;

      @include media('>=medium') {
        margin: {
          top: 2px;
        }
      }

      a {
        color: inherit;;
      }
    }
  }

  @include media('<medium') {
    .breadcrumb__list {
      display: inline-flex;;

      .breadcrumb__item {
        display: none;

        &:first-child {
          display: block;
          visibility: hidden;

          a {
            font-size: 0;
          }

          a::before {
            content: '〈 RETOUR';
            white-space: nowrap;
            font-size: rem(14px);
            visibility: visible;
          }
        }
      }
    }
    .page__meta-bottom {
      margin-top: 60px;
      padding-top: 0;
    }

    .page__meta-bottom__wrapper {
      display: block;

      .page__author {
        width: 100%;
      }

      .sharing-links {
        width: 100%;
        margin-top: 25px;
      }

      .sharing-links__legend {
        padding-right: 20px;
        color: $color-text;
        font: {
          size: rem(16px);
        };
      }
    }
  }
}
