&--shift {
  display: flex;
  margin: {
    top: 25px;
    right: -10px;
    bottom: 25px;
    left: -10px;
  }

  @include media('>=medium') {
    margin: {
      top: 60px;
      bottom: 60px;
    }
  }

  .item {
    width: calc(100% / 3);
    flex-basis: auto;
    flex-grow: 0;
    flex-shrink: 0;
    padding: 10px;

    &:nth-child(2n) {
      @include media('>=medium') {
        margin-top: $offset;
      }
    }

    &__title {
      margin: 0;
      text-transform: uppercase;
      font-size: rem(18px);
    }

    &__info {
      margin-bottom: 0;
      color: map-get($gray, 'medium');
      font-weight: 400;
    }
  }
}
