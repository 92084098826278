.button {
  display: inline-flex;
  position: relative;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font: {
    size: rem(14px);
    weight: 600;
  }

  .svg-icon {
    display: inline-block;
    margin: -2px $padding-small / 2;
    transition: fill $duration-fast $ease;
    fill: $white;

    @include media('>=medium') {
      margin: -3.5px $padding-small -3.5px 0;
    }
  }

  &--regular {
    padding: 20px 10px;
    border: 1px solid $color-primary;
    text-transform: uppercase;
    color: $white;
    z-index: 0;
    transition: color $duration $ease, transform $duration-fast $ease;

    @include media('>=medium') {
      padding: 20px 40px;
      letter-spacing: 1px;
    }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: $color-primary;
      z-index: -1;
      transition: height $duration-fast $ease;
    }

    &:hover {
      color: $color-primary;

      &::before {
        height: 0;
      }

      .svg-icon {
        fill: $color-primary;
      }
    }

    &.info {
      border: 1px solid map-get($color-utils, info);
      color: $white;

      &::before {
        height: 100%;
        background-color: map-get($color-utils, info);
      }

      &:hover {
        border: 1px solid map-get($color-utils, info);
        color: $white;
      }

      @include media('>=large') {
        &:hover {
          border: 1px solid map-get($color-utils, info);
          color: map-get($color-utils, info);

          &::before {
            height: 0;
          }
        }
      }
    }

    &.success {
      border: 1px solid map-get($color-utils, success);
      color: $white;

      &::before {
        height: 100%;
        background-color: map-get($color-utils, success);
      }

      &:hover {
        border: 1px solid map-get($color-utils, success);
        color: $white;
      }

      @include media('>=large') {
        &:hover {
          border: 1px solid map-get($color-utils, success);
          color: map-get($color-utils, success);

          &::before {
            height: 0;
          }
        }
      }
    }

    &.danger {
      border: 1px solid map-get($color-utils, danger);
      color: $white;

      &::before {
        height: 100%;
        background-color: map-get($color-utils, danger);
      }

      &:hover {
        border: 1px solid map-get($color-utils, danger);
        color: $white;
      }

      @include media('>=large') {
        &:hover {
          border: 1px solid map-get($color-utils, danger);
          color: map-get($color-utils, danger);

          &::before {
            height: 0;
          }
        }
      }
    }

    &:active {
      transform: scale(.95);
    }

    .svg-icon {
      fill: $white;
    }

    &-small {
      padding: 15px 8px;
      font-size: rem(13px);
    }

    &-light {
      background-color: transparent;
      color: $color-primary;

      .svg-icon {
        fill: $color-primary;
      }

      &::before {
        top: auto;
        bottom: 0;
        height: 2px;
        background-color: $color-primary;
      }

      &:hover {
        color: $white;

        &::before {
          height: 100%;
        }

        .svg-icon {
          fill: $white;
        }
      }
    }

    &-white {
      border-color: $white;
      background-color: transparent;
      color: $color-primary;

      .svg-icon {
        fill: $color-primary;
      }

      &::before {
        background-color: $white;
      }

      &:hover {
        color: $white;

        .svg-icon {
          fill: $white;
        }
      }
    }
  }

  &--no-bg {
    padding: {
      left: 36px;
    };
    border: 0;
    text-transform: none;
    color: $color-text;
    transition: transform $duration;

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      left: 0;
      width: 20px;
      height: 1px;
      background-color: $color-text;
      transform: translateY(-50%);
      transition: width $duration;
    }

    &:hover {
      color: inherit;
      transform: translateX(5px);

      &::before {
        width: 25px;
        height: 1px;
      }
    }

    &-primary {
      color: $color-primary;

      &:hover {
        color: $color-primary;
      }

      &::before {
        background-color: $color-primary;
      }
    }

    &-regular {
      color: $color-text;

      &:hover {
        color: $color-text;
      }

      &::before {
        background-color: $color-text;
      }
    }

    &-white {
      color: $white;

      &:hover {
        color: $white;
      }

      &::before {
        background-color: $white;
      }
    }
  }

  &--simple {
    text-transform: uppercase;
    font: {
      size: rem(11px);
      weight: 600;
    }
  }

  &--icon {
    @include media('>=medium') {
      padding: 20px 10px;
    }

    .svg-icon {
      @include media('>=medium') {
        margin: -3.5px $padding-small / 2;
      }
    }
  }
}
