// color
$white: #fff;
$black: #000;

$blue: (
    xlight: #f0f7fa,
    light: #009ee0,
    primary: #024680,
    dark: #262C60,
);

$gray: (
    dark: #1e1e1e,
    medium: #6a6a6a,
    light: #cdd1d6,
    mlight: #f0f6fb,
    xlight: #f6f6f6,
);

$color-primary: map-get($blue, primary);
$color-text: map-get($gray, dark);

$color-utils: (
    success: #028a22,
    danger: #db3832,
    warning: #f6bf4f,
    undefined: map-get($gray, light),
    info: $color-primary
);

$color-gradient-start: #00aaf9;
$color-gradient-end: #024680;
$gradient: linear-gradient(270deg, $color-gradient-start 0%, $color-gradient-end 100%);

// Break points
$breakpoints: (
    small: 640px,
    medium: 768px,
    large: 1024px,
    xlarge: 1280px,
    full: 1350px,
);

// Fonts
$open-sans: open-sans, Arial, Helvetica, sans-serif;
$raleway: raleway, Arial, Helvetica, sans-serif;
$font-primary: $open-sans;
$font-secondary: $raleway;

// Line height
$line-height: 24px;

// Duration
$duration-fast: .15s;
$duration: .3s;
$duration-slow: .6s;
$scale-duration: .5s;

// effects
$image-scale-hover: 1.05;

// Easing
$ease: ease-in-out;
$ease-in: ease-in;
$ease-out: ease-out;

// Header
$header: (
    small: (
        height: 150px
    ),
    medium: (
        height: 140px
    ),
    large: (
        height: 155px
    )
);

// Body
$body: (
    margin: 0,
    padding: map-get(map-get($header, 'large'), 'height') 0
);

// Modal
$modal: (
    overlay: (
        opacity: .4,
        background-color: $black
    )
);

$overlay: rgba(0, 0, 0, .85);

// Size
$padding-small: 15px;
$padding-medium: 40px;
$padding-large: 50px;
$padding-menu: 25px;
$padding-input-top: 22px;
$padding-input-left: calc(2 * #{$padding-small});

// Wrapper max-width
$max-width-large: #{$padding-large * 2 + 1920};
$max-width-medium: #{$padding-large * 2 + 1300};
$max-width-small: #{$padding-large * 2 + 830};

$logo-width: auto;
$logo-height-small: 35px;
$logo-height-large: 40px;
$default-logo-width: 100px; // fallback
// Companies logo width
$companies: (
    logo : (
        po: (
            width: 100px,
            height: $logo-height-small,
            top: 20px,
            breakpointMedium: (
                width: 110px,
                height: $logo-height-small,
                top: 26px,
            ),
            breakpointLarge: (
                top: 13px,
                width: 113px,
                height: 40px,
            )
        ),
        sp: (
            width: 110px,
            height: $logo-height-small,
            top: 19px,
            breakpointMedium: (
              width: 110px,
              height: $logo-height-small,
              top: 26px,
            ),
            breakpointLarge: (
              top: 13px,
              width: 125px,
              height: $logo-height-large,
            )
        ),
        abde: (
            width: 110px,
            height: $logo-height-small,
            top: 19px,
            breakpointMedium: (
                width: 110px,
                height: $logo-height-small,
                top: 26px,
            ),
            breakpointLarge: (
                top: 13px,
                width: 125px,
                height: $logo-height-large,
            )
        ),
        cdf: (
            width: 188px,
            height: $logo-height-small,
            top: 19px,
            breakpointMedium: (
                width: 188px,
                height: $logo-height-small,
                top: 23px,
            ),
            breakpointLarge: (
                width: 214px,
                height: $logo-height-large,
                top: 15px,
            )
        ),
        cmr: (
            width: 45px,
            height: 45px,
            top: 12px,
            breakpointMedium: (
                width: 45px,
                height: 45px,
                top: 19px,
            ),
            breakpointLarge: (
                width: 45px,
                height: 45px,
                top: 11px,
            )
        ),
        guiraud: (
            width: 105px,
            height: $logo-height-small,
            top: 18px,
            breakpointMedium: (
                width: 117px,
                height: $logo-height-small,
                top: 22px,
            ),
            breakpointLarge: (
                width: 105px,
                height: $logo-height-large,
                top: 13px,
            )
        ),
        maillard: (
            width: 117px,
            height: $logo-height-small,
            top: 18px,
            breakpointMedium: (
                width: 117px,
                height: $logo-height-small,
                top: 22px,
            ),
            breakpointLarge: (
                width: 133px,
                height: $logo-height-large,
                top: 13px,
            )
        ),
        malrieu: (
            width: 105px,
            height: $logo-height-small,
            top: 16px,
            breakpointMedium: (
                width: 105px,
                height: $logo-height-small,
                top: 23px,
            ),
            breakpointLarge: (
                width: 120px,
                height: $logo-height-large,
                top: 17px,
            )
        ),
        mestre: (
            width: 113px,
            height: 21px,
            top: 24px,
            breakpointMedium: (
                width: 140px,
                height: 25px,
                top: 28px,
            ),
            breakpointLarge: (
                width: 109px,
                height: 20px,
                top: 23px,
            )
        ),
        ps: (
            width: 164px,
            height: $logo-height-small,
            top: 18px,
            breakpointMedium: (
                width: 164px,
                height: $logo-height-small,
                top: 22px,
            ),
            breakpointLarge: (
                width: 187px,
                height: $logo-height-large,
                top: 13px,
            )
        ),
        sfcp: (
            width: 79px,
            height: $logo-height-small,
            top: 22px,
            breakpointMedium: (
                width: 79px,
                height: $logo-height-small,
                top: 26px,
            ),
            breakpointLarge: (
                width: 90px,
                height: $logo-height-large,
                top: 17px,
            )
        ),
        somatem: (
            width: 112px,
            height: $logo-height-small,
            top: 22px,
            breakpointMedium: (
                width: 112px,
                height: $logo-height-small,
                top: 27px,
            ),
            breakpointLarge: (
                width: 128px,
                height: $logo-height-large,
                top: 17px,
            )
        ),
    )
);

// Gap
$gap-xsmall: 6.5px;
$gap-small: 15px;
$gap-medium: 20px;

// Effects
$bg-gradient-bottom-color: rgba(0, 0, 0, .5);
$bg-gradient-top-color: rgba(0, 0, 0, 0);

// Offset
$offset: 100px;

// Shadow
$shadow-regular: 2px 2px 2px 0 rgba(0, 0, 0, .1);
$shadow-medium: 4px 4px 4px 0 rgba(0, 0, 0, .2);

:export {
  duration: $duration;
  durationSlow: $duration-slow;
  durationMedium: $duration;
  durationFast: $duration-fast;
  breakpointSmall: map-get($breakpoints, small);
  breakpointMedium: map-get($breakpoints, medium);
  breakpointLarge: map-get($breakpoints, large);
  breakpointXlarge: map-get($breakpoints, xlarge);
  breakpointFull: map-get($breakpoints, full);
  colorPrimary: map-get($blue, primary);
  colorGrayDark: map-get($gray, dark);
}
