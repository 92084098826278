&-invoice {
  max-width: 100%;
  margin-bottom: 30px;

  @include media('>=small') {
    max-width: 375px;
  }

  &__title {
    text-transform: uppercase;
    color: map-get($gray, medium);
    font: {
      family: $font-primary;
      size: rem(12px);
      weight: normal;
    }

    @include media('>=large') {
      margin-top: -4px;
    }
  }

  &__link {
    display: flex;
    height: 60px;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    background-color: map-get($blue, light);
    text-align: center;
    color: $white;
    transition: background-color $duration;

    strong {
      display: block;
      font: {
        size: rem(11px);
        weight: 600;
      }
    }

    &:hover {
      background-color: $color-primary;
      color: $white;
    }
  }
}
