.phone-modal {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  align-items: center;
  background-color: $black;
  visibility: hidden;
  opacity: 0;
  z-index: 1000;

  &__close {
    position: absolute;
    top: 30px;
    right: 30px;
    border: 0;
    background-color: transparent;

    .svg-icon {
      fill: $white;
    }
  }

  &__inner {
    width: 100%;
    padding: 30px;
  }

  &__title {
    display: block;
    margin: $padding-medium 0;
    text-align: center;
    color: $white;
    font: {
      size: rem(18px);
      weight: 600;
    }
  }

  &__item {
    margin: 30px 0;
  }

  &__link {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    background-color: $white;
    font: {
      family: $font-secondary;
      size: rem(14px);
    }
  }

  strong {
    display: block;
    padding-bottom: 5px;
    text-transform: uppercase;
    color: $black;
    font: {
      family: $font-secondary;
      size: rem(18px);
      weight: 600;
    }
  }

  &:active {
    .svg-icon {
      transform: scale(.95);
    }
  }

  .svg-icon {
    transition: transform $duration-fast $ease;
    fill: $white;
  }
}
