&__header {
  margin-bottom: $padding-medium;

  @include media('>=medium') {
    margin-bottom: 70px;
  }
}

&__filters {
  margin: {
    top: 30px;
  }
}

&__title {
  margin: 0;
  line-height: rem(40px);
  color: $color-primary;
  font: {
    family: $font-secondary;
    size: rem(30px);
    weight: 600;
  }

  @include media('>=medium') {
    line-height: rem(60px);
    font-size: rem(48px);
  }
}

&__subtitle {
  color: map-get($gray, 'medium');
  font-size: rem(13px);
}

&__buttons {
  margin: $padding-medium 0;
}

&__intro {
  @include media('>=large') {
    width: 100% / 3 * 2;
  }
}

&__meta {
  display: block;
  padding: $padding-small 0;
  text-align: right;
  color: map-get($gray, 'medium');

  @include media('>=large') {
    padding: $padding-small;
  }
}

&__cat {
  font-weight: 600;
}

&__cat,
&__date {
  font-size: rem(13px);
}

&__picture {
  display: block;

  img {
    width: 100%;
    margin: $padding-large 0;
  }
}

&__content {
  position: relative;
}

&__background {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: map-get($gray, 'xlight');
  opacity: 0;
  z-index: -1;

  @include media('>=small') {
    display: block;
  }
}

&__meta-bottom {
  padding: $padding-large * 2 0 $padding-large;

  &__wrapper {
    display: flex;
    align-items: center;
  }
}

&__author,
&__share {
  max-width: 50%;
  flex-basis: 50%;
}

&__share {
  text-align: right;
}
