&--3-images {
  flex-flow: row wrap;

  .item {
    @include media('>=medium') {
      margin-bottom: 20px;
    }

    @include media('>=large') {
      width: calc(100% / 3 - 10px);
    }
  }

  .item__title {
    span {
      text-transform: none;
    }
  }
}

