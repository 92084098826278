.sitemap {
  a {
    color: inherit;
    font-weight: bold;
  }

  &__link {
    margin: {
      top: 0;
      bottom: 25px;
    }
    cursor: pointer;
  }

  &__title {
    color: $color-primary;
    font: {
      family: $font-secondary;
      size: rem(24px);
      weight: normal;
    }

    &--level1 {
      margin: {
        top: 50px;
        bottom: 50px;
      };
      font: {
        family: $font-secondary;
        size: rem(24px);
        weight: normal;
      }

      @include media('>=medium') {
        margin: rem(40px) 0;
        line-height: rem(21px);
        font-size: rem(36px);
      }
    }

    &--level2 {
      color: $color-text;
      font: {
        family: $font-secondary;
        size: rem(14px);
        weight: bold;
      }
    }

    &--level3 {
      text-transform: uppercase;
      color: $color-text;
      font: {
        family: $font-secondary;
        size: rem(14px);
        weight: bold;
      }
    }
  }

  &__list {
    line-height: 1.8em;

    &--level1 {
      margin: {
        top: $padding-small;
        bottom: $padding-small;
      };
      border-top: 1px solid map-get($gray, xlight);

      @include media('>=medium') {
        margin: {
          top: $padding-medium;
          bottom: $padding-medium;
        };
      }

      @include media('>=large') {
        margin: {
          top: $padding-large;
          bottom: $padding-large;
        };
      }

      &:first-child {
        border-top: 0;
      }
    }

    &--level2 {
      display: flex;
      flex-flow: row wrap;
    }

    &--level3 {
      width: 100%;
      flex: 0 0 auto;
      margin: {
        right: 20px;
      }

      @include media('>=medium') {
        width: calc(100% / 2 - 20px);
      }

      @include media('>=large') {
        width: calc(100% / 3 - 20px);
      }
    }

    //> .menu__list {
    //  display: flex;
    //  flex-flow: row wrap;
    //
    //  > .menu__item {
    //    width: 100%;
    //    flex: 0 0 auto;
    //    margin-right: 20px;
    //
    //    @include media('>=medium') {
    //      width: calc(100% / 2 - 20px);
    //    }
    //
    //    @include media('>=large') {
    //      width: calc(100% / 3 - 20px);
    //    }
    //  }
    //}
  }
  //
  //.menu__link {
  //  display: block;
  //  margin-top: 10px;
  //  color: $color-text;
  //  font: {
  //    weight: bold;
  //  }
  //}
}
