&--2-blocs-hv {
  position: relative;
  margin: {
    top: 60px;
    bottom: 60px;
  }
  padding: {
    top: 60px;
    bottom: 0;
  };

  @include media('>=medium') {
    margin: {
      top: 100px;
      bottom: 100px;
    }
    padding: {
      top: 100px;
      bottom: 0;
    };
  }

  @include media('>=large') {
    margin: {
      top: 135px;
      bottom: 100px;
    }
    padding: {
      top: 100px;
      bottom: 0;
    };
  }

  .wrapper {
    position: relative;

    &::before { //graphical grey border
      content: '';
      display: block;
      position: absolute;
      top: -60px;
      right: 0;
      bottom: 50%;
      left: 0;
      background-color: map-get($gray, xlight);
      z-index: 1;

      @include media('>=medium') {
        top: -100px;
        bottom: 18%;
      }

      @include media('>=large') {
        right: 21%;
        bottom: 50%;
        left: - 50vw;
      }

      @include media('>=xlarge') {
        right: 18%;
      }
    }
  }

  .section__header {
    position: relative;
    margin: {
      top: 0;
      bottom: 35px;
    }
    z-index: 2;

    @include media('>=medium') {
      margin: {
        top: 0;
        bottom: 35px;
      }
    }

    @include media('>=large') {
      margin: {
        top: 0;
        bottom: 70px;
      }
    }
  }

  .section__title {
    margin: {
      top: 0;
      bottom: 15px;
    }

    @include media('>=large') {
      width: 56.27%;
    }

    @include media('>=xlarge') {
      width: 47%;
    }
  }

  .section__text {
    margin: {
      top: 15px;
      bottom: 0;
    }

    @include media('>=large') {
      width: 56.27%;
    }

    @include media('>=xlarge') {
      width: 47%;
    }
  }
}
