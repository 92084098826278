&__tools {
  padding: 0 $padding-small $padding-small;
  background-color: $white;

  @include media('>=medium') {
    padding: 10px 40px 20px;
  }

  @include media('>=large') {
    display: flex;
    position: absolute;
    right: 15px;
    bottom: 0;
    padding: 0;
    background-color: transparent;
  }
}
