.page {
  position: relative;
  overflow: hidden;

  @import 'page/default';
  @import 'page/blog-list';
  @import 'page/blog';
  @import 'page/news-list';
  @import 'page/news';
  @import 'page/store';
  @import 'page/services';
  @import 'page/services-list';
  @import 'page/project-sdb';
  @import 'page/projects-list';
  @import 'page/rdv';
  @import 'page/homepage';
  @import 'page/categories';
  @import 'page/products';
  @import 'page/product';
  @import 'page/electricity';
  @import 'page/store-locator';
  @import 'page/search';
  @import 'page/home';
  @import 'page/cdf-operation';
}
