&-newsletter {
  @include media('>=medium') {
    width: 50%;
    flex: 0 0 auto;
  }

  @include media('>=large') {
    width: 40%;
    flex: 0 0 auto;
  }

  @include media('>=large') {
    width: 50%;
  }

  &__title {
    display: block;
    margin: {
      top: 20px;
      bottom: 20px;
    }
    line-height: rem(24px);
    font: {
      family: $font-secondary;
      size: rem(16px);
      weight: 600;
    }

    @include media('>=medium') {
      max-width: 300px;
      margin: {
        top: 0;
        bottom: 20px;
      }
    }

    @include media('>=large') {
      max-width: 450px;
    }

    strong {
      display: block;
      font: {
        family: $font-secondary;
        size: rem(20px);
        weight: 600;
      }
    }
  }

  .form__wrapper {
    display: flex;
    font: {
      family: $font-primary;
      size: rem(12px);
    }

    @include media('>=medium') {
      width: 100%;
    }

    @include media('>=large') {
      max-width: 300px;
    }

    .form__field {
      display: block;
      height: 50px;
      flex: 1 1 auto;
      padding: {
        right: 20px;
        left: 20px;
      }
      border: {
        top: 1px solid map-get($gray, light);
        bottom: 1px solid map-get($gray, light);
        left: 1px solid map-get($gray, light);
      }
      color: map-get($gray, medium);
      font-size: rem(13px);

      &:disabled {
        cursor: not-allowed;
      }
    }

    .form__button {
      display: block;
      flex: 0 0 50px;
      border: 1px solid $color-primary;
      text-transform: uppercase;

      &:disabled {
        cursor: not-allowed;
      }

      .svg-icon {
        margin: 0 auto;
        fill: $white;
      }
    }
  }

  &__text {
    height: 0;
    overflow: hidden;
    font: {
      family: $font-primary;
      size: rem(12px);
    }

    @include media('<medium') {
      margin: 0;
    }

    @include media('>=medium') {
      height: auto;
      margin-top: 35px;
    }

    @include media('>=large') {
      max-width: 450px;
    }
  }

  &.success {
    .form__button {
      border-color: map-get($color-utils, success);
      background-color: map-get($color-utils, success);
    }
  }

  &.danger {
    .form__field {
      border: {
        top: 1px solid map-get($color-utils, danger);
        bottom: 1px solid map-get($color-utils, danger);
        left: 1px solid map-get($color-utils, danger);
      }
      color: map-get($color-utils, danger);;
    }
  }

  &:disabled {
    .form__field,
    .form__button {
      pointer-events: none;
    }
  }

  &__message {
    &.success {
      color: map-get($color-utils, success);
    }

    &.danger {
      color: map-get($color-utils, danger);
    }
  }

  &__recaptcha-text {
    display: block;
    margin: {
      top: 0;
    }
    line-height: 1.6em;
    font: {
      size: rem(10px);
    }

    @include media('>=medium') {
      width: 100%;
    }

    @include media('>=large') {
      max-width: 450px;
    }

    a {
      color: $color-primary;
    }
  }
}
