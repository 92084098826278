.scrolltop {
  position: fixed;
  right: 50px;
  bottom: 50px;
  visibility: hidden;
  opacity: 0;
  z-index: 99;
  transition: opacity $duration-fast $ease, visibility $duration-fast $ease;

  &--show {
    visibility: visible;
    opacity: 1;
  }
}
