&--side {
  .item {
    margin: {
      top: 20px;
      bottom: 20px;
    }

    @include media('>=medium') {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: {
        top: 100px;
        bottom: 100px;
      }
    }

    @include media('>=xlarge') {
      align-items: center;
    }

    &.block-link:hover {
      .item__image {
        .item__picture {
          transform: scale($image-scale-hover);
        }
      }
    }

    &:nth-child(2n + 2) {
      .item__image {
        @include media('>=medium') {
          order: 2;
        }
      }
    }

    &__wrapper {
      @include media('>=medium') {
        width: 50%;
        flex-basis: auto;
        flex-grow: 0;
        flex-shrink: 0;
        padding: {
          right: 40px;
          left: 0;
        };
      }
    }

    &__picture {
      transition: transform $scale-duration $ease-out;
    }

    &__image {
      width: 100%;
      overflow: hidden;

      @include media('>=medium') {
        width: 50%;
        flex-basis: auto;
        flex-grow: 0;
        flex-shrink: 0;
      }
    }

    &__info {
      margin: {
        bottom: 30px;
      }

      @include media('>=medium') {
        margin: {
          top: 0;
        }
      }
    }

    &__title {
      margin: {
        top: 30px;
        bottom: 25px;
      }
      font: {
        size: rem(24px);
        weight: 600;
      }

      @include media('>=medium') {
        margin: {
          top: 0;
        }
        font: {
          size: rem(36px);
        }
      }

      @include media('>=xlarge') {
        margin: {
          bottom: 15px;
        }
      }

      a {
        color: inherit;
      }
    }

    &__text {
      display: none;

      @include media('>=medium') {
        display: block;
        margin: {
          top: 25px;
          bottom: 35px;
        }
      }

      @include media('>=xlarge') {
        margin: {
          top: 15px;
        }
      }
    }

    &__cta {
      margin: {
        top: 15px;
      }

      @include media('>=medium') {
        margin: {
          top: 35px;
        }
      }
    }
  }

  @import 'side-light';
  @import 'side-large';
  @import 'side-light-shift';
  @import 'side-text';
}
