&--icon {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  margin: {
    top: 60px;
    right: auto;
    bottom: 60px;
    left: auto;
  }

  @include media('>=medium') {
    margin: {
      top: 100px;
      bottom: 100px;
    }
  }

  @include media('>=large') {
    margin: {
      top: 100px;
      bottom: 100px;
    }
  }

  @include media('>=xlarge') {
    margin: {
      top: 120px;
      bottom: 100px;
    }
  }

  .item {
    display: flex;
    width: calc(50% - #{$gap-small / 2}); //IE10
    flex-basis: auto;
    flex-flow: column nowrap;
    flex-grow: 0;
    flex-shrink: 0;
    justify-content: space-between;

    @include media('>=medium') {
      display: block;
      width: calc(50% - 20px); //IE10
      flex-basis: auto;
      flex-grow: 0;
      flex-shrink: 0;
    }

    @include media('>=large') {
      width: calc(50% - 40px); //IE10
      flex-basis: auto;
      flex-grow: 0;
      flex-shrink: 0;
    }

    @include media('>=xlarge') {
      width: calc(50% - 80px); //IE10
      flex-basis: auto;
      flex-grow: 0;
      flex-shrink: 0;
    }

    &__image {
      display: flex;
      width: auto;
      flex: 0 0 80px;
      justify-content: center;
    }

    .image {
      height: 80px;
    }

    .svg-icon {
      width: 70px;
      height: 70px;
      fill: $color-primary;
    }

    &__title {
      flex: 0 0 auto;
      margin: {
        top: 20px;
        bottom: 25px;
      }
      text-align: center;
      line-height: 1.6em;

      @include media('>=medium') {
        margin: {
          top: 35px;
          bottom: 25px;
        }
        font: {
          size: rem(24px);
        }
      }

      @include media('>=large') {
        margin: {
          top: 35px;
          bottom: 20px;
        }
      }

      a {
        color: inherit;
      }
    }

    &__wrapper {
      display: flex;
      position: relative;
      flex: 1 0 auto;
      flex-direction: column;
      justify-content: space-between;

      @include media('>=medium') {
        display: block;
      }
    }

    &__text {
      display: none;
      margin: {
        top: 25px;
        bottom: 40px;
      }
      text-align: center;
      line-height: $line-height;
      font: {
        size: rem(14px);
      }

      @include media('>=medium') {
        display: block;
      }

      @include media('>=large') {
        display: block;
        margin: {
          top: 20px;
          bottom: 40px;
        }
      }
    }

    &__cta {
      margin: {
        top: 0;
      }
      text-align: center;

      @include media('>=medium') {
        margin: {
          top: 40px;
        }
      }
    }

    .button {
      display: block;
      width: 100%;
      text-align: center;

      @include media('>=medium') {
        display: inline-block;
        width: auto;
      }
    }
  }
}
