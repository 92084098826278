.gallerie,
.gallerie-nav {
  &__controls {
    display: flex;
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    justify-content: space-between;
    transform: translate(0, -50%);
    pointer-events: none;
  }

  &__control {
    display: flex;
    position: relative;
    width: 50px;
    height: 50px;
    align-items: center;
    justify-content: center;
    border: 0;
    background-color: transparent;
    cursor: pointer;
    pointer-events: visible;

    .svg-icon {
      fill: $white;
    }

    &::before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: map-get($gray, 'dark');
      opacity: .7;
      z-index: -1;
      transition: opacity $duration-fast $ease;
    }

    &:disabled {
      visibility: hidden;
      opacity: 0;
    }

    &:hover {
      &::before {
        opacity: 1;
      }
    }
  }

  &__control,
  &__close {
    transition: transform $duration $ease, opacity $duration $ease, visibility $duration $ease;

    &:active {
      transform: scale(.95);
    }
  }
}

.gallerie {
  &__close {
    display: block;
    position: absolute;
    top: $padding-small / 2;
    right: $padding-small / 2;
    padding: $padding-small;
    border: 0;
    background: transparent;
    cursor: pointer;
    z-index: 1;

    @include media('>small') {
      top: $padding-small;
      right: $padding-small;
    }

    .svg-icon {
      fill: $white;
    }
  }

  &__wrapper {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    background-color: $overlay;
    visibility: hidden;
    opacity: 0;
    z-index: 1000;
  }

  &__picture {
    img {
      width: 100%;
      padding: $padding-small;

      @include media('>small') {
        width: auto;
        max-width: 90vw;
        max-height: 90vh;
      }
    }
  }

  &__controls {
    padding: $padding-small / 2;

    @include media('>small') {
      padding: $padding-small;
    }
  }

  &-nav {
    position: relative;

    &::before { //graphical grey border
      @include media('>=medium') {
        content: '';
        position: absolute;
        top: -80px;
        right: -100vw;
        left: 85%;
        height: 110px;
        border: {
          top: 10px solid map-get($gray, xlight);
          left: 10px solid map-get($gray, xlight);
        }
      }
    }

    &.no-border {
      &::before { //remove graphical grey border
        @include media('>=medium') {
          content: none;
        }
      }
    }

    &:hover {
      .gallerie-nav__control {
        @include media('>small') {
          visibility: visible;
          opacity: 1;
        }

        &:disabled {
          visibility: hidden;
          opacity: 0;
        }
      }
    }

    &__wrapper {
      position: relative;
    }

    &__item {
      width: 100%;

      @include media('>small') {
        width: auto;
      }
    }

    &__picture {
      display: block;
      position: relative;
      width: 100%;
      padding: $padding-small;

      @include media('>small') {
        width: auto;
        height: 380px;
      }

      @include media('>=large') {
        height: 600px;
      }

      img {
        width: 100%;

        @include media('>small') {
          width: auto;
          height: 100%;
        }
      }
    }

    &__controls {
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &__control {
      @include media('>small') {
        visibility: hidden;
        opacity: 0;
      }

      &--prev {
        left: $padding-small / 2;

        @include media('>small') {
          left: $padding-medium;
        }
      }

      &--next {
        right: $padding-small / 2;

        @include media('>small') {
          right: $padding-medium;
        }
      }
    }

    &__progress {
      position: relative;
      width: calc(100% - 2 * #{$padding-small});
      height: 2px;
      margin: 0 $padding-small;
      background-color: map-get($gray, 'xlight');

      @include media('>small') {
        display: none;
      }
    }

    &__progress-bar {
      position: absolute;
      top: 0;
      left: 0;
      width: 0;
      height: 100%;
      background-color: $color-primary;
    }
  }
}
