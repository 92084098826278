&__wrapper {
  display: flex;
  position: relative;
  flex-direction: column;
  z-index: 0;

  @include media('>=large') {
    max-width: $max-width-large;
    flex-direction: column-reverse;
    margin: 0 auto;
  }
}
