&--products {
  margin: {
    bottom: 60px;
  }

  .page__header {
    margin: {
      bottom: 25px;
    }

    @include media('>=medium') {
      margin: {
        bottom: 50px;
      }
    }
  }

  .products {
    position: relative;
    margin: {
      bottom: 20px;
    }
    padding: {
      bottom: 0;
    }

    @include media('>=large') {
      margin: {
        bottom: 10px;
      }
    }

    &::before { //graphical grey border
      @include media('>=xlarge') {
        content: '';
        position: absolute;
        top: 200px;
        right: -100vw;
        left: 83%;
        height: 600px;
        border: {
          bottom: 10px solid map-get($gray, xlight);
          left: 10px solid map-get($gray, xlight);
        }
      }
    }

    &.no-border {
      &::before {
        display: none;
      }
    }
  }

  .item--extra {
    .button {
      color: $color-primary;
    }
  }

  .more-products {
    margin: {
      bottom: 0;
    }

    @include media('>=medium') {
      margin: {
        top: 100px;
      }
    }
  }

  .catalog,
  .products__push_wrapper{
    @include media('>large') {
      max-width: 1024px;
      margin: {
        right: auto;
        left: auto;
      }
    }
  }

  .contents--catalog {
    margin: {
      top: 60px;
    }

    @include media('>=medium') {
      margin: {
        top: 120px;
      }
    }
  }
}
