// Same as
// - page--services-list
// - page--categories
// - page--projects-list  /salle-de-bains (sdb, chauffage)

&--services-list {
  .breadcrumb__list {
    display: inline-flex;
  } 

  .page__header {
    margin: {
      bottom: 0;
    };

    p {
      margin-bottom: 0;
    }
  }

  .contents {
    margin: {
      top: 0;
      bottom: 0;
    };
  }

  .item {
    margin: {
      top: 60px;
      bottom: 60px;
    };

    @include media('>=medium') {
      margin: {
        top: 100px;
        bottom: 100px;
      };
    }

    @include media('>=large') {
      margin: {
        top: 100px;
        bottom: 100px;
      };
    }

    @include media('>=xlarge') {
      margin: {
        top: 150px;
        bottom: 150px;
      };

      &:first-child {
        margin-top: 120px;
      }
    }

    &__picture {
      position: relative;
      height: 0;
      overflow: hidden;
      padding-top: 77%;
      background-color: map-get($gray, xlight);

      .image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: auto;
      }
    }
  }

  // display txt for mobile
  .contents--side {
    .item__text {
      display: block;
    }
  }
}
