&__links {
  margin: 0 (- $padding-small);

  .icon-links__list {
    @include media('<medium') {
      padding: {
        right: 5px;
        left: 5px;
      };
    }
  }

  .icon-links__item {
    @include media('<medium') {
      padding: {
        right: 10px;
        left: 10px;
      };
    }
  }

  @include media('>=large') {
    margin: 0;
  }
}
