.form {
  display: flex;
  position: relative;
  max-width: 100%;
  flex-wrap: wrap;
  justify-content: space-between;

  ::placeholder {
    color: map-get($gray, 'medium');
  }

  &__separator {
    width: 100%;
    height: 1px;
    margin: $padding-small 0;
    background-color: map-get($gray, 'xlight');
  }

  &__wrapper {
    position: relative;
    width: 100%;
    flex: 0 0 auto;
    padding: $padding-small 0;

    @include media('>=small') {
      width: calc(50% - 10px);
    }

    @include media('>=large') {
      width: calc(50% - 7.5px);
    }

    @include media('>=xlarge') {
      width: calc(50% - 15px);
    }

    &--full {
      width: 100%;

      .form__errors {
        position: relative;
      }
    }
  }

  &__label,
  &__link {
    display: inline-flex;
    position: absolute;
    top: calc(#{$padding-input-top} + #{$padding-small});
    left: $padding-input-left;
    flex-wrap: wrap;
    color: map-get($gray, 'medium');
    transition: margin $duration-fast $ease, font-size $duration-fast $ease;
  }

  &__link {
    position: relative;
    top: auto;
    left: auto;
    color: $color-text;
  }

  &__extra-label {
    display: block;
    text-transform: uppercase;
    color: map-get($gray, 'medium');
  }

  &__field {

    &--input,
    &--textarea {
      width: 100%;
      margin: 0;
      padding: $padding-input-top $padding-input-left;
      border: 1px solid map-get($gray, 'medium');
      background-color: $white;
      transition: border $duration-fast $ease;

      &:focus ~ .form__label,
      &:not([data-filled='0']) ~ .form__label {
        margin-top: - $padding-small;
        font-size: rem(10px);
      }

      &:read-only ~ .form__label,
      &:-moz-read-only ~ .form__label {
        margin-top: - $padding-small;
        font-size: rem(10px);
      }

      &:focus {
        border: 1px solid map-get($gray, 'light');
      }

      &:read-only {
        background-color: map-get($gray, 'xlight');
        pointer-events: none;
      }
    }

    &--select {
      width: 100%;
      margin: 0;
      padding: $padding-input-top $padding-input-left;
      border: 1px solid map-get($gray, 'medium');
      background-color: $white;
      transition: border $duration-fast $ease;

      ~ .form__label {
        display: none;
      }
    }

    &--error {
      border-color: map-get($color-utils, 'danger');

      &:focus {
        border-color: map-get($color-utils, 'danger');
      }
    }

    &--radio,
    &--checkbox {
      ~ .form__label {
        position: relative;
        top: auto;
        left: auto;
        color: $color-text;
      }
    }
  }

  &__button {
    background-color: $color-primary;
    cursor: pointer;
    color: $white;
  }

  &__choice {
    display: inline-block;
    position: relative;
    margin: $padding-small / 2 0;
    padding-right: $padding-small;

    &--block {
      display: block;
    }

    .form__field {
      display: block;
      position: absolute;
      opacity: 0;
      z-index: -1;
    }

    .form__field + .form__label,
    .form__link {
      padding-left: $padding-input-left;
      cursor: pointer;
      line-height: $line-height;

      &::before,
      &::after {
        content: '';
        display: block;
        position: absolute;
        bottom: calc(100% - 19px);
        left: 0;
        width: $padding-small;
      }

      &::before {
        height: $padding-small;
        border: 1px solid $color-primary;
        transition: background-color $duration-fast $ease;
      }

      &::after {
        height: 2px;
        background-color: $color-primary;
        transition: height $duration-fast $ease;
      }
    }

    .form__field:checked + .form__label,
    .form__link--active {

      &::after {
        height: $padding-small;
      }
    }

    &:hover {
      .form__label,
      .form__link {
        &::before {
          background-color: map-get($gray, 'xlight');
        }
      }
    }
  }

  &__info {
    padding: $padding-small 0;
    font-size: rem(12px);
  }

  &__errors {
    position: absolute;
    left: 0;
    bottom: -5px;

    li {
      color: map-get($color-utils, 'danger');
      font-size: rem(12px);
    }
  }

  [hidden] {
    display: block;
    position: absolute;
    opacity: 0;
  }
}

form:disabled {
  input,
  button,
  textarea {
    cursor: not-allowed;
  }
}
