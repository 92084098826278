.accordion {
  z-index: 1;

  &__toggle {
    display: inline-block;
    cursor: pointer;

    svg {
      display: inline-block;
      width: 10px;
      height: 10px;
      margin: {
        left: 5px;
      }
      vertical-align: middle;
      transform: rotate(-90deg);
      transition: transform $duration;
      fill: $color-primary;
    }

  }

  &.accordion--open {
    .accordion__toggle svg {
      transform: rotate(0);
    }
  }

  &__content {
    max-height: 0;
    overflow: hidden;
  }

  &--navigation {
    display: none;
    position: relative;
    color: map-get($gray, medium);
    font: {
      family: $font-secondary;
      size: rem(11px);
    }

    @include media('>=large') {
      display: block;
    }

    .accordion__content {
      position: absolute;
      top: 20px;
      left: -40px;
      width: calc(100% + 40px);
      margin-top: 20px;
      background-color: map-get($gray, xlight);
    }

    .navigation {
      margin: 40px;

      &__item {
        margin: {
          top: 20px;
          bottom: 20px;
        }
        text-transform: uppercase;
        color: map-get($gray, medium);
        font: {
          family: $font-secondary;
          size: rem(11px);
        }

        &.active {
          color: $color-primary;
          font-weight: bold;
        }
      }
    }
  }
}
