.rdv {
  &__rdv {
    display: flex;
    flex-flow: row wrap;
    align-content: center;
    align-items: center;

    &::after {
      content: '';
      width: 0;
      height: 0;
      overflow: hidden;
      clear: both;
      visibility: hidden;
    }

    @include media('>=large') {
      display: block;
    }

    .item {
      &__picture {
        display: block;
        position: relative;
        width: 100%;
        height: 0;
        overflow: hidden;
        padding-top: 76.8%;
        background-color: map-get($gray, xlight);

        @include media('>=medium') {
          width: 50%;
          flex-basis: auto;
          padding-top: (76.8% / 2);
        }

        @include media('>=large') {
          float: left;
        }

        .image {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: auto;
        }
      }

      &__title {
        width: 100%;
        flex-basis: auto;
        margin: {
          top: 25px;
        }
        line-height: 1.4em;
        color: $color-primary;
        font: {
          family: $font-secondary;
          size: 24px;
          weight: 600;
        }

        @include media('>=medium') {
          width: 50%;
          flex-basis: auto;
          margin: {
            top: 0;
            bottom: 0;
          }
          padding: {
            left: 40px;
          }
          font: {
            size: 26px;
          }
        }
        @include media('>=large') {
          float: right;
        }

        @include media('>=xlarge') {
          padding: {
            top: 5%;
            left: 50px;
          }
          line-height: 1.2em;
          font: {
            size: 36px;
          }
        }

        @include media('>=full') {
          padding: {
            left: 80px;
          }
        }
      }

      &__link {
        color: inherit;
      }

      &__content {
        width: 100%;
        flex-basis: auto;

        @include media('>=medium') {
          margin: {
            top: 25px;
          };
        }
        @include media('>=large') {
          width: 50%;
          flex-basis: auto;
          float: right;
          padding: {
            left: 40px;
          }
        }
        @include media('>=xlarge') {
          padding: {
            bottom: 5%;
            left: 50px;
          }
        }
        @include media('>=full') {
          padding: {
            left: 80px;
          }
        }
      }

      &__text {
        p {
          @include media('>=medium') {
            //margin-top: 0;
          }
        }
      }

      &__cta {
        .button {
          width: 100%;
        }

        @include media('>=medium') {
          margin-top: 30px;

          .button {
            width: auto;
          }
        }
      }
    }
    .image {
      position: relative;
      width: 100%;
      margin-top: 0;
      z-index: 2;
      transition: transform $scale-duration $ease-out;

      @include media('>=medium') {
        margin-top: 0;
      }
    }

    &.block-link:hover {
      .image {
        transform: scale($image-scale-hover);
      }
    }
  }

  $large-offset: 150px;
  $xlarge-offset: 100px;
  $map-width-large: 470px;
  $map-width-xlarge: 570px;

  &__map {
    position: relative;
    margin: {
      top: 60px;
      bottom: 60px;
    }
    padding-top: 60px;

    @include media('>=medium') {
      display: flex;
      flex-flow: row wrap;
      margin: {
        top: 100px;
        bottom: 80px;
      }
    }

    @include media('>=large') {
      padding: {
        top: $large-offset;
      }
    }

    @include media('>=xlarge') {
      padding: {
        top: $large-offset;
      }
    }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 60px;
      left: 50%;
      width: 100vw;
      margin: {
        left: -50vw;
      }
      background-color: map-get($gray, xlight);
      z-index: 1;

      @include media('>=large') {

      }

      @include media('>=xlarge') {
        bottom: 100px;
      }
    }

    .item {
      &__title {
        position: relative;
        line-height: 1.4em;
        color: $color-primary;
        font: {
          family: $font-secondary;
          size: 24px;
          weight: 600;
        }
        z-index: 2;

        @include media('>=medium') {
          width: 100%;
          flex-basis: auto;
          margin-bottom: 20px;
          font: {
            size: 36px;
          }
        }

        @include media('>=large') {
          width: 100%;
          padding: {
            right: calc(100% - #{$map-width-large} - 40px);
          };
        }

        @include media('>=xlarge') {
          padding: {
            right: calc(100% - #{$map-width-xlarge} - 40px);
          };
        }
      }

      &__link {
        position: relative;
        color: inherit;
        z-index: 2;
      }

      &__content {
        position: relative;
        z-index: 2;

        @include media('>=medium') {
          width: 50%;
          flex-basis: auto;
          padding: {
            right: 40px;
            bottom: 40px;
          }
        }

        @include media('>=large') {
          width: calc(100% - #{$map-width-large});
          padding: {
            right: 100px;
          }
        }

        @include media('>=xlarge') {
          width: calc(100% - #{$map-width-xlarge});
          padding: {
            right: 140px;
          };
        }
      }

      &__text {
        position: relative;
        z-index: 2;

        p {
          @include media('>=medium') {
            margin-top: 0;
          }
        }
      }

      &__cta {
        position: relative;
        z-index: 2;

        .button {
          width: 100%;
        }

        @include media('>=medium') {
          margin-top: 30px;

          .button {
            width: auto;
          }
        }
        @include media('>=large') {
          margin-bottom: 80px;
        }
      }

      &__picture {
        position: relative;
        overflow: hidden;
        z-index: 2;

        @include media('>=medium') {
          display: block;
          width: 50%;
          flex-basis: auto;
        }

        @include media('>=large') {
          top: #{$large-offset * -1.5};
          width: $map-width-large;
          margin-bottom: #{$large-offset * -1.5};
        }

        @include media('>=xlarge') {
          top: -200px;
          width: $map-width-xlarge;
          margin-bottom: -200px;
        }
      }
    }

    .image {
      position: relative;
      width: 100%;
      margin-top: 60px;
      z-index: 2;
      transition: transform $scale-duration $ease-out;

      @include media('>=medium') {
        margin-top: 0;
      }
    }

    &.block-link:hover {
      .image {
        transform: scale($image-scale-hover);
      }
    }
  }
}

