@use "sass:color";

.switcher {
  @include media('>=medium') {
    display: none;
  }

  &__list {
    display: inline-flex;
    align-items: center;
    padding: 5px;
    background-color: $white;
    box-shadow: $shadow-regular;
  }

  &__item {
    &--active {
      .switcher__button {
        background-color: color.adjust($color-primary, $lightness: 70%);
        color: $color-primary;

        .svg-icon {
          fill: $color-primary;
        }
      }
    }
  }

  &__button {
    display: inline-flex;
    align-items: center;
    padding: $padding-small;
    border: 0;
    background-color: $white;
    text-transform: uppercase;
    letter-spacing: .5px;
    color: map-get($gray, 'medium');
    font-weight: 600;

    .svg-icon {
      width: 10px;
      height: 10px;
      margin-right: 10px;
    }

    &:hover {

      .svg-icon {
        fill: $color-primary;
      }
    }
  }
}
