&-nav {
  position: relative;
  margin: {
    top: 35px;
    bottom: 35px;
  }
  font: {
    family: $font-primary;
    size: rem(12px);
    weight: 600;
  }

  @include media('>=medium') {
    width: calc(50% - 40px);
    flex: 0 0 auto;
    margin-top: 0;
    margin-bottom: 0;
    font-size: rem(14px);
  }
  @include media('>=large') {
    width: 25%;
    flex: 0 0 auto;
  }

  @include media('>=xlarge') {
    display: flex;
    width: calc(50% - 200px);
    flex: 0 0 auto;
    justify-content: space-between;
  }

  &__list {
    width: 60%;

    @include media('>=large') {
      width: 100%;
      padding-right: 20px;
    }

    &--left {
      position: absolute;
      top: 0;
      right: 0;
      width: 40%;
      z-index: 1;

      @include media('>=medium') {
        position: static;
      }

      @include media('>=large') {
        width: 100%;
      }

      .footer-nav__item:first-child {
        margin-top: 0;
      }
    }
  }

  &__item {
    margin: {
      top: 26px;
      bottom: 26px;
    }

    @include media('>=medium') {
      margin: {
        top: 0;
        bottom: 21px;
      }
    }
    @include media('>=large') {
      &:first-child {
        margin-top: 6px;
      }
    }

    .footer-more {
      display: inline-block;
      position: relative;
      width: 7px;
      height: 1em;
      margin: {
        left: 7px;
      }
      cursor: pointer;

      &::before,
      &::after {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        left: 0;
        width: 7px;
        height: 1px;
        background-color: $color-text;
        transition: opacity $duration, transform $duration;
      }

      &::before {
        transform: translateY(-50%) rotate(90deg);
      }
      &::after {
        transform: translateY(-50%) rotate(0deg);
      }
    }
    &.open {
      .footer-more {
        &::before {
          transform: translateY(-50%) rotate(0deg);
        }
        &::after {
          opacity: 0;
          transform: translateY(-50%) rotate(180deg);
        }
      }
    }
  }

  &__link {
    text-transform: uppercase;

    &--has-submenu {

    }
  }

  &__submenu {
    max-height: 0;
    overflow: hidden;
    font: {
      weight: normal;
    }

    .footer-nav__link {
      text-transform: none;
    }

    .footer-nav__item {
      margin-top: 21px;
    }
  }

  &__link--has-submenu.open + .footer-nav__submenu {
    display: block;
  }
}
