&--catalog {
  background-color: $white;

  .item {
    margin: {
      top: 0;
      bottom: 0;
    }

    @include media('>=medium') {
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      justify-content: space-between;

    }

    @include media('>=xlarge') {
      align-items: center;
    }

    &:hover {
      .item__image {
        .item__picture {
          transform: scale($image-scale-hover);
        }
      }
    }

    &__wrapper {
      padding: {
        top: 40px;
        right: 40px;
        bottom: 40px;
        left: 40px;
      }

      @include media('>=medium') {
        width: calc(100% - 290px); //IE10
        flex-basis: auto;
        flex-grow: 0;
        flex-shrink: 0;
        padding: {
          top: 60px;
          right: 0;
          bottom: 60px;
          left: 60px;
        }
      }

      @include media('>=large') {
        width: calc(100% - 310px); //IE10
        flex-basis: auto;
        padding: {
          top: 80px;
          right: 0;
          bottom: 80px;
          left: 80px;
        }
      }
    }

    &__picture {
      transition: transform $scale-duration $ease-out;
    }

    &__image {
      display: none;
      width: 100%;
      overflow: visible;

      @include media('>=medium') {
        display: block;
        flex-basis: 290px;
        flex-grow: 1;
        flex-shrink: 0;
        padding: {
          //top: 60px;
          //bottom: 60px;
        }
        transform: translateX(40px);
      }

      @include media('>=large') {
        flex-basis: 310px;
        padding: {
          top: 0;
          bottom: 0;
        }
        transform: translate(40px, 30px);
      }
    }

    &__title {
      margin: {
        top: 0;
        bottom: 15px;
      }
      font: {
        size: rem(24px);
        weight: 600;
      }

      @include media('>=medium') {
        margin: {
          top: 0;
        }
        font: {
          size: rem(24px);
        }
      }

      @include media('>=xlarge') {
        margin: {
          bottom: 15px;
        }
      }

      a {
        color: inherit;
      }
    }

    &__text {
      margin: {
        top: 15px;
        bottom: 30px;
      }

      @include media('>=medium') {
        display: block;
        margin: {
          bottom: 40px;
        }
      }

      @include media('>=xlarge') {
        margin: {
          top: 15px;
        }
      }
    }

    &__cta {
      margin: {
        top: 30px;
      }

      @include media('>=medium') {
        margin: {
          top: 40px;
        }
      }

      .button {
        width: 100%;

        @include media('>=medium') {
          width: auto;
        }
      }
    }
  }

  @import 'catalog-light';
}
