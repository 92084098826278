.search-results {
  position: absolute;
  left: -$padding-small;
  width: calc(100% + 2 * #{$padding-small});
  overflow: hidden;

  @include media('>=medium') {
    left: -$padding-medium;
    width: calc(100% + 2 * #{$padding-medium});
  }

  @include media('>=large') {
    position: relative;
    left: auto;
    width: 100%;
  }

  &__slider {
    height: 0;
    overflow: auto;
    padding: 0 $padding-small;
    background-color: $white;
    opacity: 0;
    transform: translate(0, -100%);

    @include media('>=medium') {
      padding: 0 $padding-medium;
    }

    @include media('>=large') {
      padding: 0;
    }
  }

  &__wrapper {
    @include media('>=large') {
      display: flex;
      max-width: $max-width-medium;
      flex-direction: row-reverse;
      margin: 0 auto;
      padding: $padding-large * 2 $padding-medium;
    }
  }

  &__title {
    display: block;
    margin: 0;
    padding: 0 0 2 * $padding-small;
    text-transform: uppercase;
    color: map-get($gray, 'medium');
    font: {
      size: rem(12px);
      weight: 400;
    }
  }

  &__section {
    position: relative;
    margin: $padding-medium 0 80px;

    @include media('>=large') {
      margin: 0;
    }

    &--categories {
      @include media('>=large') {
        flex: 0 0 (100% / 3);
      }

      .item {
        &__link {
          display: block;
          padding: $padding-small 0;
          color: $color-primary;
          font: {
            weight: 600;
          }
        }
      }
    }

    &--products {
      @include media('>=large') {
        width: calc(100% / 3 * 2);
        flex: 0 0 auto;
      }

      .search-results__list {
        display: flex;
        flex-direction: row;
        //justify-content: space-between;
        @include media('>=medium') {
          margin: {
            right: ($padding-small / -2);
            left: ($padding-small / -2);
          }
        }
      }

      .item {
        width: calc(100% / 3 - 15px);
        flex-basis: auto;

        @include media('>=medium') {
          margin: {
            right: ($padding-small / 2);
            left: ($padding-small / 2);
          }
        }

        @include media('>=xlarge') {
          width: calc(100% / 3 - 35px);
        }

        &__picture {
          display: block;
          position: relative;
          height: 0;
          overflow: hidden;
          margin: $padding-small 0;
          padding-top: 100%;
          background-color: map-get($gray, xlight);

          .image {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: auto;
            margin: 0;
          }
        }

        &__categorie {
          margin: $padding-small 0;
          color: map-get($gray, 'medium');
          font-size: rem(11px);
        }

        &__link {
          display: block;
          margin: $padding-small 0;
          text-transform: uppercase;
          font: {
            family: $font-secondary;
            weight: 600;
          }
        }
      }
    }
  }

  &__link {
    display: inline-flex;
    position: absolute;
    top: 0;
    right: 0;
    align-items: center;
    padding: 0 0 2 * $padding-small;
    text-transform: uppercase;
    color: $color-primary;
    font: {
      size: rem(11px);
      weight: 600;
    };

    .svg-icon {
      width: $padding-small;
      height: $padding-small;
      margin-left: $padding-small;
      fill: $color-primary;
    }
  }
}
