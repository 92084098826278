@use "sass:color";

.filters {
  border: 1px solid $color-primary;

  @include media('>=medium') {
    border: 0;
  }

  &--open {
    .svg-icon {
      transform: rotate(180deg);
    }
  }

  &__title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: $padding-small * 1.25 $padding-small * 2;
    background-color: color.adjust($color-primary, $lightness: 70%);
    cursor: pointer;
    color: $color-primary;
    font: {
      size: rem(13px);
      weight: 600;
    }

    @include media('>=medium') {
      display: none;
    }
  }

  &__list {
    max-height: 0;
    overflow: hidden;

    @include media('>=medium') {
      display: inline-flex;
      max-height: none;
      flex-wrap: wrap;
      margin: 0 (- $padding-small / 2);
    }
  }

  &__item {
    @include media('>=medium') {
      padding: $padding-small / 2;
    }

    &--active {

      .filters__link {
        @include media('>=medium') {
          background-color: color.adjust($color-primary, $lightness: 70%);
          color: $color-primary;

          &::after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 1px;
            background-color: $color-primary;
          }
        }
      }
    }
  }

  &__link {
    display: block;
    position: relative;
    padding: 20px $padding-small * 2;
    background-color: color.adjust($color-primary, $lightness: 70%);
    cursor: pointer;
    color: map-get($gray, 'medium');
    font: {
      size: rem(13px);
      weight: 600;
    }
    transition: background-color $duration-fast $ease, border $duration-fast $ease, color $duration-fast $ease;

    @include media('>=medium') {
      padding: $padding-small / 2 $padding-small * 2;
      border: 1px solid map-get($gray, 'medium');
      background-color: transparent;
    }

    &:hover {
      @include media('>=medium') {
        border-color: $color-primary;
        background-color: $color-primary;
        color: $white;
      }
    }
  }

  .svg-icon {
    transition: transform $duration $ease;
    pointer-events: none;
  }
}
