.rgpd-modal {
  position: relative;
  opacity: 0;
  z-index: 1000;
  transition: opacity $duration;

  p:first-child {
    margin-top: 0;
  }

  .link--blue {
    color: #024680;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 0;
      height: 1px;
      background-color: #024680;
      transition: width 0.15s ease-in-out;
    }

    &:hover, &:active {
      &::before {
        width: 100%;
      }
    }
  }

  .buttons {
    margin-top: 40px;
    display: flex;

    @media screen and (max-width: 721px) {
      margin-top: 25px;
      display: block;
    }

    .button {
      margin-right: 15px;
      display: inline-block;
      padding: 15px 25px;

      &.rgpd-accept-all {
        order: 3;
      }

      &#rgpd-save, &.rgpd-settings-toggle {
        order: 2;
      }

      &#rgpd-reject-all {
        order: 1;
      }

      @media screen and (max-width: 721px) {
        display: block;
        width: 100%;
        margin-top: 10px;
        margin-right: 0;

        &:first-child {
          margin: 0;
        }
      }
    }
  }


  &__overlay {
    display: block;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, .5);
    z-index: 100;
  }

  &-enter-active,
  &.modal-enter-done {
    opacity: 1;
    display: block;
  }

  &__wrapper {
    position: fixed;
    top: 50%;
    left: 50%;
    min-width: 720px;
    max-width: 820px;
    max-height: 90vh;
    background-color: $white;
    z-index: 200;
    transform: translate(-50%, -50%);
    overflow-y: scroll;

    @media screen and (max-width: 721px) {
      min-width: 0;
      left: 0;
      transform: none;
      bottom: 0;
      top: initial;
    }
  }

  &__content {
    padding: 50px 70px;
    overflow-y: auto;

    &.hidden {
      display: none;
    }

    @media screen and (max-width: 721px) {
      padding: 35px;
    }
  }

  &__close {
    position: absolute;
    top: 30px;
    right: 30px;
    display: block;
    cursor: pointer;
    transition: transform $duration;

    &:hover {
      transform: rotate(90deg);
    }

    @media screen and (max-width: 721px) {
      top: 15px;
      right: 15px;
    }
  }

  .list {
    &__item {
      position: relative;

      .form {
        &__content {
          &.disabled {
            color: #aaaaaa;
            text-transform: uppercase;

            .svg-icon {
              fill: #aaaaaa;
              display: inline;
              vertical-align: bottom;
            }
          }
        }
      }
    }
  }

  &__checkbox {
    visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;

    &:checked {
      + .rgpd-modal__label {
        color: #024680;

        &::after {
          opacity: 1;
        }

        + .accordion__toggle {
          color: #024680;
        }
      }
    }

    + .rgpd-modal__label {
      position: relative;
      display: inline-block;
      top: auto;
      left: auto;
      padding: {
        left: 24px;
      }
      line-height: 1.4em;
      cursor: pointer;
      text-transform: uppercase;

      + .accordion__toggle {
        vertical-align: top;
      }

      &:hover {
        &::before {
          border-color: #024680;
        }
      }

      &::before,
      &::after {
        content: '';
        display: block;
        position: absolute;
      }

      &::before {
        left: 0;
        width: 19px;
        height: 19px;
        border: 1px solid #aaaaaa;
        background-color: #ffffff;
      }

      &::after {
        top: 4px;
        left: 4px;
        width: 11px;
        height: 11px;
        background-color: #024680;
        opacity: 0;
      }
    }
  }

  .title {
    color: #024680;
    margin-bottom: 15px;
    font-size: 30px;

    @media screen and (max-width: 721px) {
      font-size: 20px;
    }
  }
}
