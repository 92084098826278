.modal {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  background-color: $overlay;
  visibility: hidden;
  opacity: 0;
  z-index: 1000;

  &__inner {
    position: relative;
    width: 100%;
    height: 100%;

    @include media('>=medium') {
      width: 70vw;
      height: 70vh;
    }
  }

  &__close {
    position: absolute;
    top: 0;
    right: 0;
    padding: $padding-small;
    border: 0;
    background-color: $color-primary;
    cursor: pointer;
    font-size: 0;

    @include media('>=medium') {
      top: 15vh;
      right: 15vw;
    }

    &:active {
      .svg-icon {
        transform: scale(.95);
      }
    }

    .svg-icon {
      transition: transform $duration-fast $ease;
      fill: $white;
    }
  }
}
