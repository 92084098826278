@use "sass:math";

.menu {
  font: {
    family: $font-secondary;
    weight: 600;
  }

  &__item {
    &--hidden-tablet {
      display: block;

      @include media('>=medium') {
        display: none;
      }
    }
  }

  &__link {
    display: block;
    position: relative;
    padding: $padding-menu;
  }

  &--main {
    .menu__list {
      @include media('>=large') {
        display: inline-flex;
      }
    }

    .menu__item {
      position: relative;
      border-bottom: 1px solid map-get($gray, 'xlight');

      &--with-submenu {
        @include media('>=large') {
          &:nth-child(3) {
            .menu__submenu {
                left: -60px;
            }
          }

          &:nth-child(4) {
            .menu__submenu {
                left: -168px;
            }
          }
        }

        @include media('>=xlarge') {
          &:nth-child(3) {
            .menu__submenu {
              left: 0;
            }
          }

          &:nth-child(4) {
            .menu__submenu {
              left: 0;
            }
          }
        }

        &::before,
        &::after {
          content: '';
          position: absolute;
          top: calc(#{$padding-menu} + #{$padding-small} / 2);
          left: 0;
          width: $padding-small;
          height: 2px;
          background-color: $color-text;
          transition: background-color $duration $ease, transform $duration $ease;
        }

        &::before {
          transform: rotate(90deg);
        }

        &:hover {
          &::after,
          &::before {
            background-color: $color-primary;
          }
        }
      }

      &--expanded {
        &::before {
          transform: rotate(0deg);
        }
      }
      &:hover {
        &::after,
        &::before {
          background-color: $color-primary;
        }

        .menu__link {
          &:before {
            @include media('>=large') {
              opacity: 1;
            }
          }
        }
      }

      &--active {

        &::after,
        &::before {
          background-color: $color-primary;
        }

        .menu__link {
          color: $color-primary;

          &:before {
            @include media('>=large') {
              opacity: 1;
            }
          }
        }
      }

      @include media('>=large') {
        border: 0;

        &:after,
        &:before {
          content: none;
        }

        &:first-child {
          > .menu__link {
            padding-left: 0;

            &:before {
              width: calc(100% - 12px);
            }
          }

          .menu__submenu {
            margin-left: -$padding-medium;
          }
        }
      }

      @include media('>=full') {
        &:first-child {
          > .menu__link {

            &:before {
              width: calc(100% - #{$padding-menu});
            }
          }
        }
      }
    }

    .menu__link {
      position: relative;
      padding-left: calc(#{$padding-menu} + #{$padding-small});
      text-transform: uppercase;

      @include media('>=large') {
        padding: {
          right: 12px;
          left: 12px;
        }
      }

      @include media('>=full') {
        padding: {
          right: $padding-menu;
          left: $padding-menu;
        }
      }

      &:before {
        @include media('>=large') {
          content: '';
          position: absolute;
          bottom: 0;
          width: calc(100% - 2 * 12px);
          height: 1px;
          background-color: $color-primary;
          opacity: 0;
        }

        @include media('>=full') {
          width: calc(100% - 2 * #{$padding-menu});
        }
      }
    }

    .menu__submenu {
      max-height: 0;
      overflow: hidden;

      @include media('>=large') {
        display: grid;
        position: absolute;
        max-height: none;
        padding: $padding-menu;
        background-color: $white;
        visibility: hidden;
        opacity: 0;
        z-index: -1;
        grid-template-columns: repeat(2, auto);
        grid-template-rows: 1fr;
        grid-auto-flow: column;
      }

      .menu__list {
        @include media('>=large') {
          display: grid;
          padding: 60px 0;
          background-color: $white;
          -ms-grid-rows: auto !important;
          grid-template-columns: repeat(4, auto);
          grid-template-rows: repeat(4, 1fr);
          grid-gap: 0 50px;
          grid-auto-flow: column;
        }
      }

      .menu__link {
        @include media('>=large') {
          min-width: 160px;
          padding: $padding-small;
          white-space: nowrap;

          &:before {
            content: none;
          }
        }
      }

      .menu__item {
        @include media('>=large') {

          &:first-child {
            .menu__link {
              padding-left: $padding-small;
            }
          }
        }
      }

      .menu__preview {
        @include media('>=large') {
          width: 270px;
          height: 270px;
          margin: auto;
          background-size: cover;
        }
      }

      .menu__item {
        border-bottom: 0;
      }

      .menu__link {
        text-transform: none;
        color: map-get($gray, 'medium');

        &:hover {
          color: $color-primary;
        }
      }
    }

    @include media('>=large') {
      max-width: 100%;
      flex-basis: auto;

      &:before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 50%;
        width: 100vw;
        height: 1px;
        background-color: map-get($gray, 'xlight');
        transform: translate(-50%, 0);
        transition: opacity $duration $ease;
      }

      .is-home & {
        &::before {
          opacity: 0;
        }
      }
    }
  }

  &--second {
    border-bottom: 1px solid map-get($gray, 'xlight');

    @include media('>=large') {
      display: flex;
      position: relative;
      border-bottom: 0;

      &:before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100vw;
        height: 1px;
        background-color: map-get($gray, 'xlight');
      }
    }

    .menu__list {
      padding: $padding-menu 0;

      @include media('>=medium') {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
      }

      @include media('>=large') {
        display: inline-flex;
        flex-wrap: nowrap;
        padding: 0;
      }
    }

    .menu__item {
      &:last-child {
        .menu__link {
          @include media('>=large') {
            padding-right: 0;
          }
        }
      }

      @include media('>=medium') {
        max-width: 50%;
        flex-basis: 50%;
      }

      @include media('>=large') {
        max-width: 100%;
        flex-basis: auto;
      }
    }

    .menu__link {
      padding: $padding-small 0;

      @include media('>=large') {
        padding: 0 $padding-menu;
        text-align: center;
        line-height: 1.5em;
      }
    }

    @include media('>=large') {
      display: flex;
      max-width: 40%;
      flex-basis: 40%;
      justify-content: flex-end;
    }
  }

  &--third {
    @include media('<small') {
      padding: {
        bottom: 60px;
      }
    }

    @include media('>=large') {
      display: flex;
      position: relative;

      &:before {
        content: '';
        position: absolute;
        right: 0;
        bottom: 0;
        width: 100vw;
        height: 1px;
        background-color: map-get($gray, 'xlight');
      }
    }

    .menu__list {
      display: flex;
      flex-wrap: wrap;
      padding: $padding-menu 0;

      @include media('>=large') {
        display: inline-flex;
        flex-wrap: nowrap;
        align-items: center;
        padding: 0;
      }
    }

    .menu__item {
      max-width: 50%;
      flex-basis: 50%;

      @include media('>=large') {
        max-width: 100%;
        flex-basis: auto;
      }
    }

    .menu__link {
      padding: $padding-small 0;
      color: map-get($gray, 'medium');
      font-size: rem(14px);

      &:hover {
        color: $color-primary;
      }

      @include media('>=large') {
        padding: $padding-menu $padding-small;
      }

      @include media('>=full') {
        padding: $padding-menu;
      }
    }

    @include media('>=large') {
      max-width: 60%;
      flex-basis: 60%;
      /* See companies-adjustments.scss */
      padding-left: $default-logo-width + $padding-small;
    }
  }
}
