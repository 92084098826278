&--electricity {
  margin: {
    bottom: 60px;
  }

  @include media('>=medium') {
    margin: {
      bottom: 100px;
    }
  }

  .page__background {
    display: block;
  }

  .page__header {
    margin: {
      bottom: 0;
    }
  }

  .page__title {
    text-align: center;

    @include media('>=medium') {
      margin: {
        top: 60px;
      }
    }
  }

  .page__desc {
    p {
      text-align: center;
    }
  }

  .page__picture {
    position: relative;
    height: 0;
    overflow: hidden;
    margin: 50px 0;
    padding-top: 68%;
    background-color: map-get($gray, xlight);

    @include media('>=large') {
      padding-top: 59.4%;
    }

    .image {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: auto;
      margin: 0;
    }
  }

  .item__link {
    color: $color-primary;
  }

  .cms-content {
    @include media('>=medium') {
      margin: {
        bottom: 100px;
      }
    }
  }

  .contents--border-icon {
    margin: {
      bottom: 0;
    }

    @include media('>=medium') {
      margin: {
        top: 100px;
      }
    }
  }
}
