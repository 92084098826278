.icon-links {
  text-align: right;

  &__list {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  &__item {
    padding: $padding-small;

    @include media('>=large') {
      margin-top: 7px;
      padding: 18px $padding-small;
    }

    &:first-child {
      flex: 1 0 auto;
      text-align: left;
    }

    &--hidden-title {
      .icon-links__title {
        display: none;
      }
    }

    &--hidden-desktop {
      @include media('>=large') {
        display: none;
      }
    }

    &--hidden-mobile {
      display: none;
      @include media('>=medium') {
        display: block;
      }
    }
  }

  &__link {
    padding: 0;
    border: 0;
    background-color: transparent;

    &:hover {
      .svg-icon {
        fill: $color-primary;
      }
    }

    @include media('>=medium') {
      display: flex;
      align-items: center;
    }
  }

  &__title {
    display: block;
    padding-top: $padding-small;
    text-transform: uppercase;
    font: {
      family: $font-secondary;
      size: rem(10px);
      weight: 600;
    }

    @include media('>=medium') {
      width: 80px;
      padding-top: 0;
      padding-left: $padding-small;
      text-align: left;
      text-transform: none;
      font-size: rem(14px);
    }

    @include media('>=large') {
      display: none;
    }

    @include media('>full') {
      display: block;
    }
  }

  &__svg {
    display: flex;
    justify-content: center;
  }

  .svg-icon {
    transition: color $duration $ease;
  }
}
