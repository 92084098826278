.form-catalogs {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  gap: 15px;

  &__item {
    background-color: map-get($gray, 'mlight');
    flex: 0 0 100%;
    text-align: center;
    padding: 30px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;

    @include media('>=large') {
      display: flex;
      flex-direction: row-reverse;
      padding: 10px 10px 20px 30px;
      justify-content: space-between;
    }
  }

  &__item--2, &__item--2 + &__item--2 {
    @include media('>=small') {
      flex: 1 1 calc(50% - 7.5px);
    }
    @include media('>=large') {
      flex: 1 1 100%;
    }
  }

  &__item--3, &__item--3 + &__item--2 {
    @include media('>=small') {
      flex: 1 1 calc(50% - 7.5px);
    }
    @include media('>=large') {
      flex: 1 1 100%;
    }
  }

  &__item--3 + &__item--3 + &__item--3 {
    flex: 1 1 100%;
  }

  .catalog {
    align-items: center;

    &__info {
      text-align: center;

      @include media('>=large') {
        padding-right: 30px;
        text-align: left;
      }
    }

    &__title {
      margin: 25px 0 15px;
      color: map-get($blue, 'primary');
      text-transform: uppercase;
      font: {
        size: rem(12px);
        weight: normal;
      }

      @include media('>=large') {
        margin: 0 0 10px;
      }
    }

    &__subtitle {
      margin: 15px 0;
      color: map-get($blue, 'primary');
      text-transform: uppercase;
      font: {
        size: rem(30px);
        weight: bold;
      }

      @include media('>=large') {
        margin: 10px 0 30px;
      }

    }
    &__cta {
      margin: 15px 0 0;

      @include media('>=large') {
        margin: 30px 0 0;
      }

      .button {
        background-color: $white;
        padding-left: 20px;
        padding-right: 20px;
      }
    }

    &__image {
      width: 162px;
      height: 210px;
      display: block;
      margin: 0 auto;
      transform: rotate(5deg);

      @include media('>=large') {
        transform: rotate(0);
      }

      .image {
        width: 100%;
        box-shadow: $shadow-medium;
      }
    }
  }
}
